.container {
  position: relative;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  height: 238px;
  &.accepted {
    .containerLink {
      width: 100%;
      height: 100%;
      flex: auto;
      display: grid;
      grid-template-columns: 4% 74% 19%;
      grid-template-rows: 15% 50% 25%;
      row-gap: 1rem;
      column-gap: 1.5rem;
      color: var(--main-font-color);
      padding: 1rem;
      .card_component {
        &_btn {
          flex-direction: row;
          justify-content: center;
          span.btn {
            &:first-child {
              margin-right: 2rem;
            }
          }
        }
      }

      @media (max-width: 1440px) {
        grid-template-columns: 4% 64% 28%;
        grid-template-rows: 15% 45% 25%;
      }

      @media (max-width: 767px) {
        padding: 0.5rem;
        row-gap: 0.5rem;
        column-gap: 0.5rem;
        grid-template-columns: 70% 30%;
        grid-template-rows: 20% 55% 20%;
        padding: 0.5rem;
      }
      @media (max-width: 415px) {
        padding: 0.5rem;
        row-gap: 0.5rem;
        column-gap: 0.5rem;
        grid-template-columns: 100%;
        grid-template-rows: 10% 10% 55% 10% 10%;
        padding: 0.5rem;
      }
    }
    &.delivered {
      background-color: var(--c-orange-300);
      &:hover {
        background-color: #d9a220;
      }
    }
    &.DESIGN {
      color: var(--main-font-color);
      background: var(--card-design-dataflow-bg);

      &:hover {
        background: var(--card-design-dataflow-bg-hover);
      }
    }
    &.OPEN {
      background-color: var(--c-lilac-300);
      &:hover {
        background-color: #6565a8;
      }
      .containerLink {
        color: var(--c-white-color);
        background-image: url('/views/_assets/images/logos/logo-blanco.png');
        .dataflowDates {
          color: var(--c-white-color);
          text-align: right;
        }
      }
    }
    &.CLOSED {
      background-color: var(--c-gray-75-opacity-08);
      &:hover {
        background-color: var(--c-gray-75);
      }
      .containerLink {
        color: var(--c-white-color);
        background-image: url('/views/_assets/images/logos/logo-blanco.png');
        .dataflowDates {
          color: var(--c-white-color);
          text-align: right;
        }
      }
    }
  }
  &.DESIGN,
  &.OPEN,
  &.CLOSED {
    box-shadow: var(--card-item-box-shadow);
    transform: scale(1, 1);
    box-shadow: var(--dataflow-item-box-shadow);
    transition: var(--dataflow-item-transition);
    &:hover {
      transform: scale(1.01, 1.01);
      box-shadow: var(--dataflow-item-box-shadow-hover);
      transition: var(--dataflow-item-transition);
    }
  }
  &:first-of-type {
    //margin-top: 2rem;
  }
  &:last-child {
    margin-bottom: 4rem;
  }
  @media (max-width: 415px) {
    max-width: 290px;
    height: auto;
  }
}
.pinContainer {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 50px 0;
  border-color: transparent var(--c-corporate-blue) transparent transparent;
  border-radius: 0 20px 0 240px;
  position: absolute;
  right: 0;
  top: 0;
  svg {
    font-size: 14pt;
    margin-left: 0.9rem;
    margin-top: 0.7rem;
    color: var(--c-white-color);
    cursor: pointer;
    &.pinned {
      transform: rotate(0deg);
      color: var(--c-corporate-yellow);
      &:hover {
        color: var(--c-corporate-yellow);
      }
    }
    &.notPinned {
      transform: rotate(90deg);
      &:hover {
        color: var(--c-corporate-yellow);
      }
    }
  }
}
.pinShowed {
  opacity: 1;
}
.pinHidden {
  opacity: 0;
}

.wrap_card_component {
  &:last-child {
    margin-bottom: 5rem;
  }
}

.containerLink {
  background-image: url('/views/_assets/images/logos/logo-gris.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 150% 50%;
  .icon {
    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0px;
    font-size: 2.5rem;
    opacity: 0.6;
  }
  .upperIcon {
    grid-column: 1/2;
    grid-row: 1/3;
    margin: 0px;
    font-size: 1.5rem;
  }

  .dataflowDates {
    grid-column: 3/3;
    grid-row: 1/2;
    color: var(--main-font-color);
    font-size: 1rem;
    margin-left: auto;
    margin-right: 3rem;
    span {
      font-weight: normal;
    }
  }

  .dateBlock {
    white-space: nowrap;
    font-weight: bold !important;
  }

  .role {
    grid-row: 1/2;
    grid-column: 2/2;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    p {
      margin: 0px;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
  }

  .text {
    grid-row: 2/3;
    grid-column: 2/3;
    .title {
      margin: 0 0 0.5rem 0 !important;
      font-size: 1.62rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    p {
      word-break: break-all;
      text-overflow: ellipsis;
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .status {
    grid-row: 3/4;
    grid-column: 3/3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 3rem;
    text-align: right;
    p {
      margin: 0px;
      font-weight: bold;
      span {
        font-weight: normal;
      }
    }
  }

  .obligation {
    grid-row: 2/4;
    grid-column: 2/3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    p {
      padding: 0.25rem 0 0.25rem 0;
      margin: 0;
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        font-weight: normal;
      }
    }
  }

  .toolbar {
    grid-column: 3/4;
    grid-row: 3/4;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    opacity: 0.6;
    span {
      -moz-user-select: none;
      user-select: none;
      -webkit-user-select: none;
      font-size: 1.5rem;
      color: var(--gray-70);
      font-size: 2rem;
      &:first-of-type {
        margin-right: 1rem;
      }
    }
    .rep_button {
      background-color: var(--c-blue-120);
      color: var(--c-white-color);
      border: 0px;
      margin: 0 0 0.5rem 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-width: 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      font: normal normal 400 1rem/1.25rem Arial, sans-serif;
      font-weight: 600;
      padding: 0.75rem 1rem;
      text-decoration: none;
      border-radius: 3px;

      &:hover {
        cursor: pointer;
        background-color: var(--c-white-color);
        border: 2px solid var(--c-blue-120);
        color: var(--c-blue-120);
        padding: calc(0.75rem - 2px) calc(1rem - 2px);
      }
      &:last-child {
        margin: 0px;
      }
    }
    i {
      font-size: 3.5em !important;
      color: var(--c-blue-120);
      fill: var(--c-blue-120);
    }
  }
  @media (max-width: 1024px) {
    .text {
      grid-column: 2/4;
      padding-right: 1rem;
    }
  }
  @media (max-width: 767px) {
    .icon {
      display: none;
    }
    .dataflowDates {
      grid-column: 2/2;
      font-size: 0.9rem;
    }
    .obligation {
      grid-column: 1/2;
      font-size: 0.9rem;
    }
    .status {
      grid-column: 2/3;
      justify-content: center;
      margin-right: 1rem;
      font-size: 0.9rem;
    }
    .role {
      grid-column: 1/3;
      justify-content: center;
      font-size: 0.9rem;
    }
    .toolbar {
      grid-column: 2/3;
      justify-content: center;
      align-items: center;
      span {
        font-size: 1.5rem;
      }
    }
    .text {
      grid-column: 1/3;
      .title {
        font-size: 1.25rem;
        white-space: pre-wrap;
      }
      p {
        font-size: 0.9rem;
        text-overflow: ellipsis;
        word-break: break-all;
      }
    }
  }
  @media (max-width: 415px) {
    .role {
      grid-row: 1/2;
    }
    .dataflowDates {
      grid-column: 1/1;
      grid-row: 2/3;
      text-align: left;
    }
    .text {
      grid-row: 3/4;
    }
    .obligation {
      grid-row: 4/5;
    }
    .status {
      grid-column: 1/1;
      grid-row: 5/6;
      text-align: left;
    }
  }
}

.tooltip {
  max-width: 300px;
  word-break: break-all;
}


.compressedList.compressedList{
  @media screen and (min-width:1300px) {
    height:190px;
    .obligation p,
    .text p,
    .upperIcon,
    .icon,
    .role{
      display: none;
    }
    .text{
      order:-1;
      h3{
        max-width: 250px;
      }
    }
    > a {
      display: flex !important;
      flex-direction: column;
    }
    .status{
      text-align: left;
    }
    .dataflowDates{
      margin-left: 0;
      text-align: left!important;
    }
  }
  @media screen and (min-width:1600px) {
    .text h3{
      max-width: 400px;
    }
  }
  @media screen and (min-width:1900px) {
    .text h3{
      max-width: 500px;
    }
  }
  @media screen and (min-width:2200px) {
    .text h3{
      max-width: 600px;
    }
  }
}