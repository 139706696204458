.block {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .multiSelect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100% !important;
    border-radius: 0px !important;
    border: 0px !important;
    color: var(--font-filters) !important;
    font-size: 0.8333rem !important;

    .label {
      clip: auto;
      height: 42px;
      overflow: visible;
      width: 90%;
    }
  }
}

.selectHeader {
  color: var(--font-filters) !important;
}

.block + span {
  display: flex;
  align-items: center;
  font-size: 12pt !important;
  width: 25%;
}

.block + span > button {
  position: relative;
  &:hover {
    background-color: transparent !important;
  }
}

.block + span > button > span:first-child {
  position: absolute;
  color: var(--datatable-header-info-button-color) !important;
  left: 0.5em;
  top: 0.5em !important;
}

@media (min-width: 600px) {
  .block {
    width: 50% !important;
  }
}

@media (max-width: 640px) {
  .block + span {
    width: 100%;
  }
  .block {
    .multiselectFilter,
    .label {
      width: 100% !important;
    }
  }
}

@media (max-width: 935px) {
  .block {
    label {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }
}

@media (max-width: 1695px) {
  .block {
    height: 3rem;
  }
}
