.userDataContainer {
  text-align: center;
  padding: 1rem;
}

.userPersonalName {
  color: var(--subtitle-color);
  font-weight: bold;
  margin-top: 0.5rem;
  font-size: 1.25em;
}

.userMail {
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  font-size: 1em;
  color: var(--main-font-color);
}
.userName {
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  font-size: 1em;
  color: var(--main-font-color);
}
