.modalEmpty {
  min-height: 50vh;
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  align-items: center;
}

.modalData {
  min-height: 50vh;
  align-items: start;
}

.noReferencingWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  h3 {
    &:first-child {
      margin-bottom: 0;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.notMatchingWrap {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  top: 45%;
  left: 45%;
}

.idColum {
  width: 120px;
}
