@import '_styles/mixins';

.ButtonsBar {
  margin-bottom: 0.5rem;
  padding: 0.25rem;

  .menuWrapper {
    width: 16rem;
  }
}

.Dialog {
  width: 55vw;
  .checkboxWrapper {
    margin-top: 1rem;
    user-select: none;
    :global .p-checkbox {
      margin-left: 0 !important;
    }
    label {
      margin-left: 0.5rem !important;
      a {
        color: var(--main-font-color);
        &:hover {
          color: var(--main-font-color);
          cursor: pointer;
        }
      }
    }
  }
}

.checkboxLabel {
  padding-left: 0.5rem;
  a {
    color: var(--main-font-color);
    &:hover {
      color: var(--main-font-color);
      cursor: pointer;
    }
  }
}

.FileUpload .span {
  width: 100%;
  min-height: 120px;
}

.warning > span {
  color: red;
}

:global .p-tabview.p-tabview-top {
  padding-bottom: 80px;
}

.InputSwitchContainer {
  width: 100%;
}

.InputSwitchDiv {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  width: fit-content;
}

.paginatorValidationViewer :global .p-dropdown-panel {
  top: -6rem !important;
}

:global body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  @include shadow-0em;
}

.replaceDataLabel {
  cursor: pointer;
}

.switch {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-items: flex-end;

  .wrap {
    align-items: center;
    display: flex;
    margin-left: auto;

    .text {
      font-size: 0.9em;
      font-weight: bold;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding: 5px;
      color: var(--main-font-color);
    }
  }
}

.progressSwitchWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .switchDivInput {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    flex-direction: row;
    flex-grow: 1;

    .switchDiv {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }
  .stepProgressBar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.validationsFooter {
  display: flex;
  justify-content: space-between;
}

.pointer {
  cursor: pointer;
}
