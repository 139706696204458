.p-growl {
  top: 70px;
  opacity: 0.95;
  filter: alpha(opacity=95);
  width: auto !important;
  min-width: 25rem !important;
  max-width: 50rem !important;
}
.p-growl.p-component.p-growl-topright {
  z-index: 5100 !important;
}

.p-growl-topright {
  top: 80px !important;
}
