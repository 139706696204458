@import '_styles/mixins';

.actionTemplate {
  display: flex;
}
.addAnotherOne {
  font-weight: bold;
  margin: 0 1.5rem 0 0.5rem;
  cursor: pointer;
}
.addAnotherOneWrapper {
  display: inline-block;
  vertical-align: middle;
}

.addEditRecordDialog {
  @include for-phone-landscape-up {
    max-width: 90%;
  }

  @include for-tablet-landscape-up {
    max-width: 80%;
  }

  @include for-desktop-up {
    max-width: 60%;
  }
}

.chips {
  display: flex !important;
  justify-content: center;
}
.coordinatesMoreInfo {
  @include for-phone-landscape-up {
    width: 90vw;
  }

  @include for-tablet-landscape-up {
    width: 80vw;
  }

  @include for-desktop-up {
    width: 70vw;
  }
}
.currentPageWrapper {
  label {
    margin: 0 0.5rem;
  }
  input {
    height: 1.75rem;
  }
}
.fieldColumn {
  width: 95px;
}
.fieldInfoDialogWrapper {
  max-width: 80vw;
  max-height: 80vh;
  width: 35vw !important;
}
.iconTooltipLevelError {
  width: 1.5em;
}
.iconTooltipWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.itemTable {
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > table {
      width: 95%;
      margin: 0.5rem;
    }
  }
}

.requiredTemplateCheck {
  float: center;
  color: var(--treeview-table-icon-color);
}
.requiredTemplateWrapper {
  display: flex;
  justify-content: center;
}
.saveButton {
  margin-top: 0.5rem !important;
}
.validationCol {
  width: 15px;
}

:global .p-datatable-scrollable-body::-webkit-scrollbar {
  height: 16px;
  width: 16px;
}
:global .p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
}
:global .p-datatable-scrollable-body::-webkit-scrollbar-thumb:hover {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 2px;
}

:global .p-datatable-scrollable-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

:global .p-datatable-scrollable-body::-webkit-scrollbar-button:single-button {
  background-color: transparent;
  display: block;
  border-style: solid;
  height: 16px;
  width: 8px;
}

:global .p-datatable-scrollable-body::-webkit-scrollbar-button:single-button:vertical:increment {
  background-color: var(--bg);
  border-width: 8px 8px 0 8px;
  border-color: var(--c-gray-75) transparent transparent transparent;
}
:global .p-datatable-scrollable-body::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-color: var(--bg);
  border-width: 0px 8px 8px 8px;
  border-color: transparent transparent var(--c-gray-75) transparent;
}

:global .p-datatable-scrollable-body::-webkit-scrollbar-button:single-button:horizontal:increment {
  background-color: var(--bg);
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent var(--c-gray-75);
}
:global .p-datatable-scrollable-body::-webkit-scrollbar-button:single-button:horizontal:decrement {
  background-color: var(--bg);
  border-width: 8px 8px 8px 0px;
  border-color: transparent var(--c-gray-75) transparent transparent;
}
