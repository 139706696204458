.reportingObligation {
  min-height: calc(70vh - 2rem);
  position: relative;
  display: flex;
  flex-direction: column;

  .switch {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    position: absolute;
    right: 0;
    top: 0;

    .text {
      color: var(--main-font-color);
      font-size: 0.9em;
      font-weight: bold;
      margin-block-end: 0.5em;
      margin-block-start: 0.5em;
      margin-inline-end: 0px;
      margin-inline-start: 0px;
      padding: 10px !important;
    }
  }
}

.selectedObligation {
  margin-top: auto;
  padding-top: 1em;

  &.selected {
    position: absolute;
    bottom: 0;
  }
}

.spinner {
  left: 0 !important;
}
