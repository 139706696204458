.nonEditableData {
  font-style: italic;
  font-weight: bold;
}

.pointWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  .completeCoordinatesWrapper {
    max-height: 75px;
    overflow-y: auto;
    margin-bottom: 0.5rem;
  }
}

.epsg {
  width: 5rem;
  color: var(--main-font-color);
  font-size: 0.9em;
  font-weight: bold;
}
