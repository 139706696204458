.dashboardWithoutData {
  height: 56vh;
}

.noDashboard {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorPicker {
  margin-left: 3px;

  > input {
    font-size: 12px !important;
  }
}

.colorPickerWrap {
  border: 1px solid var(--c-gray-50);
  padding: 20px;
}
.colorPickerItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.colorPickerItem:nth-of-type(2) {
  margin: 0 1rem;
}
.fieldsetContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboardWraper {
  display: flex;
  justify-content: flex-start;
}
.chartDiv {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
.dashboardTitle {
  margin: 0px;
  color: var(--main-font-color);
}
.dashboardWarning {
  text-align: end;
  font-style: italic;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--main-font-color);
}
