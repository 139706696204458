.footer {
  position: relative;
  bottom: 0;
  background-color: var(--header-footer-bg);
  padding-top: 3rem;
  .footerTop {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    .contentBlock {
      h3 {
        border-bottom: 2px solid var(--c-gray-50);
        color: var(--eu-footer-title);
      }
      .linksWrapper {
        display: flex;
        flex-flow: column wrap;
        align-content: flex-start;
        p {
          margin-bottom: 1rem;
        }
        @media (max-width: 768px) {
          flex-flow: column;
        }
      }
      &:first-of-type {
        margin-right: 2rem;
        width: 64%;
        height: 175px;
        overflow: hidden;
        .linksWrapper {
          width: 100%;
          max-height: 80%;
          p {
            width: 50%;
            &:nth-of-type(-n + 3) {
              width: 51.5%;
            }
          }
        }
        @media (max-width: 1160px) {
          height: auto;
        }
        @media (max-width: 768px) {
          width: 100%;
          height: auto;
          .linksWrapper {
            p {
              width: 100% !important;
            }
          }
        }
      }
      &:nth-of-type(2) {
        width: 34%;
        @media (max-width: 768px) {
          width: 100%;
          height: auto;
          .linksWrapper {
            p {
              width: 100% !important;
            }
          }
        }
      }
      &:nth-of-type(3) {
        width: 100%;
        height: 300px;
        .linksWrapper {
          width: 100%;
          max-height: 80%;
          p {
            width: 33%;
          }
        }
        @media (max-width: 900px) {
          width: 100%;
          height: auto;
          overflow: hidden;
          .linksWrapper {
            height: auto;
            p {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  .footerBottom {
    padding: 2rem 0;
    background-color: var(--eu-footer-bottom);
    a {
      color: var(--eu-link-footer-bottom) !important;
      margin-right: 3rem;
      &:hover {
        color: var(--eu-link-hover-footer-bottom) !important;
      }
    }
    @media (max-width: 768px) {
      > div {
        display: flex;
        flex-direction: column;
        a {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}
