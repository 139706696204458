/*
 * FileUpload
 */
@import '_styles/mixins';

.p-fileupload-buttonbar .p-fileupload-choose.p-disabled input {
  cursor: default;
}

.p-fileupload-buttonbar {
  padding: 0.5em;
  border-bottom: 0 none;
}

.p-fileupload-buttonbar {
  padding: 0.5em;
  border-bottom: 0 none;
}

.p-fileupload-buttonbar .p-button {
  vertical-align: middle;
  margin-right: 0.25em;
}

.p-fileupload-content {
  padding: 1em;
  position: relative;
  transition: border-color 0.3s;
  overflow: hidden;
}

.p-fileupload-content.p-fileupload-highlight {
  border-color: var(--c-corporate-blue);
}

.p-fileupload-files img {
  border: none;
}

.p-fileupload-files {
  display: table;
}

.p-fileupload-row {
  display: table-row;
  div {
    label {
      font-size: 11pt;
    }
  }
}

.p-fileupload-row {
  > div {
    display: table-cell;
    padding: 0.5em 1em;
    vertical-align: middle;
  }
  div {
    color: var(--main-font-color);
  }
}

.p-fileupload-content .p-progressbar {
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
  height: 0.25em;
  border: 0 none;
}

.p-fileupload-content .p-progressbar-value {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border: 0 none;
}

/* Simple */
.p-fileupload-choose {
  position: relative;
  overflow: hidden;
}

.p-fileupload-choose input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  text-align: right;
  filter: alpha(opacity=0);
  direction: ltr;
  cursor: pointer;
  overflow: hidden;
  z-index: -1;
}

.p-fileupload-choose input[type='file'] {
  cursor: pointer;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type='file'] {
  display: none;
}

/* p-fluid */
.p-fluid .p-fileupload .p-button {
  width: auto;
}

.p-fluid .p-fileupload-content .p-button-icon-only {
  width: 2em;
}

.chooseButton {
  display: flex;
  margin-left: 0 !important;
  width: 100%;
  .chooseInput {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    & + label {
      max-width: 100% !important;
      width: 100%;
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.infoButton {
  background-color: var(--c-white-color) !important;
  border-radius: 50% !important;
  color: var(--datatable-header-info-button-color) !important;
  font-size: 12pt !important;
  height: 0.7em !important;
  margin-left: 0.4rem !important;
  width: 0.7em !important;

  > span {
    left: 46% !important;
    margin-left: -0.48em !important;
    margin-top: -0.5em !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

.invalidExtensionMsg {
  align-items: center;
  display: flex;
  font-weight: bold;
  height: 30px;
  margin: 1rem 0 0 0;
  opacity: 1;
  transition: all 0.3s ease 0.15s;
  color: var(--errors);

  &.isValid {
    height: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
  }
}

.replaceCheckboxWrapper {
  margin-top: 1rem;
  display: inline-block;
  :global .p-checkbox {
    margin-left: 0 !important;
  }
  label {
    margin-left: 0.5rem !important;
    user-select: none;
    cursor: pointer;
    span {
      color: var(--main-font-color);
    }
    span:hover {
      color: var(--main-font-color);
    }
  }
}

.s3CheckboxWrapper {
  margin-top: 1rem;
  display: inline-block;
  margin-left: 1rem;
  :global .p-checkbox {
    margin-left: 0 !important;
  }
  label {
    margin-left: 0.5rem !important;
    user-select: none;
    cursor: pointer;
    span {
      color: var(--main-font-color);
    }
    span:hover {
      color: var(--main-font-color);
    }
  }
}

.dialogFooter {
  display: flex;
  justify-content: space-between;
}

.replaceCheckboxSpanDisabled {
  cursor: not-allowed !important;
  color: var(--c-gray-50) !important;
}
.iconPreview {
  font-size: 20pt;
  color: var(--main-font-color);
}

.dialogDefaultCustomFileUpload {
  @include for-phone-landscape-up {
    width: 90vw !important;
  }

  @include for-tablet-landscape-up {
    width: 70vw !important;
  }

  @include for-desktop-up {
    width: 50vw !important;
  }

  @include for-big-desktop-up {
    width: 40vw !important;
  }
}

.sizeDiv {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  top: 0;
}
