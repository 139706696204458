h2 {
  color: var(--main-font-color);
}

.searchInput {
  margin-bottom: 1rem;
  width: 90%;
}

.columnButton {
  width: 90%;
  font-size: 0.9rem !important;
  margin-bottom: 1rem !important;
}

.dialog :global .p-dialog-content {
  padding-bottom: 0 !important;
}

.leftSideBar {
  width: 50px;
  z-index: 1000;
  background-color: var(--leftSideBar-bg);
  transition: 0.5s;
  overflow-x: hidden;
  white-space: nowrap;
  overflow: hidden;

  .barSection {
    --middle-height: calc(100% - var(--top-height) - var(--bottom-height) - (1rem * 3));
    margin: 1rem 0;
    height: var(--middle-height);

    &:last-of-type {
      height: var(--bottom-height);
      padding-top: 0;
    }

    a {
      padding: 8px;
      text-decoration: none;
      font-size: 1.2rem;
      color: var(--left-sidebar-text-color);
      cursor: pointer;
      display: block;

      &:first-of-type {
        top: 1rem;
      }

      &:last-of-type {
        bottom: 2rem;
      }

      i {
        margin-right: 12px;
      }
    }

    img {
      &:first-child {
        margin-right: 12px;
      }
    }
  }

  hr {
    width: 80%;
    border-color: var(--c-gray-15);
    margin-top: 0;
    margin-bottom: 0;
  }

  &.open {
    width: 200px;
  }
}

.leftSideBar a .leftSideBar a:hover:not(:first-child) {
  color: var(--c-white-color);
}

.leftSideBarText {
  font-size: 14px;
}

.leftSideBarTextTitle {
  font-size: 22px;
}

.leftSideBarUserText {
  font-size: 14px;
}

.leftSideBarUserIcon {
  margin-right: 12px;
}

.leftSideBarElementWrapper {
  display: flex;
  align-items: center;
}

.leftSideBarElementAnimation {
  transition: all 0.08s ease;
}

.leftSideBarElementAnimation:hover {
  transform: scale(1.25);
  transition: 0.08s auto;
}

.leftSideBarLogo:hover {
  transition: 0.3s ease-in;
  animation: blink 0.3s;
}

.arrowToggleBtn {
  cursor: pointer !important;
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
