.contentWrap {
  border-radius: 3px;
  height: 100%;
  margin-top: 1rem;
  width: 90vw;

  .overlay {
    position: relative;
  }

  .openWebformButton {
  }

  .overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    pointer-events: none; /* Allows clicks to pass through the overlay */
  }

  .title {
    align-items: center;
    border-radius: 3px;
    border: var(--webform-table-border);
    color: var(--title-color);
    display: flex;
    font-weight: 400;
    justify-content: space-between;
    margin: 0.5rem 0;
    padding: 1rem;
  }

  .addRecordButton{
    margin-left: auto;
  }
  
  .wrapper {
    transition: top 0.3s ease; /* Only transition the top property */
    width: 100%; /* Ensure it starts with full width */
  }
  
  .stickyWrapper {
    position: fixed;
    top: 64px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 1);
    width:90vw;
  }

  .openWebformButton{
    margin-left:auto;
  }
  
  .initialWrapper {
    position: static;
    top: initial; /* This ensures there's no conflict */
  }
  
  .nonExistTable {
    color: var(--errors);
  }
}
