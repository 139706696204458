@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin for-phone-landscape-up {
  @media (min-width: 415px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin shadow-1px($shadow-color) {
  box-shadow: inset 0px 0px 0px 1px $shadow-color;
}

@mixin shadow-0em {
  box-shadow: 0 0 0 0em var(--c-blue-120) !important;
}

@mixin responsiveDialog {
  @include for-phone-landscape-up {
    width: 90vw !important;
  }

  @include for-tablet-landscape-up {
    width: 80vw !important;
  }

  @include for-desktop-up {
    width: 60vw !important;
  }

  @include for-big-desktop-up {
    width: 50vw !important;
  }
}

@mixin responsiveBigDialog {
  @include for-phone-landscape-up {
    width: 90vw !important;
  }

  @include for-desktop-up {
    width: 80vw !important;
  }
}

@mixin responsiveCardsGrid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: minmax(80%, 1fr);

  @media (min-width: 778px) {
    grid-template-columns: repeat(2, minmax(45%, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
  }

  @include for-big-desktop-up {
    grid-template-columns: repeat(4, minmax(20%, 1fr));
  }
}
