.userProfileCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.userProfile {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.userProfile > a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.userProfile .avatar {
  color: var(--c-gray-75);
  width: 50px;
  height: 50px;
  vertical-align: middle;
}

i {
  vertical-align: middle;
  display: inline;
}

.logOut {
  font-size: 18px;
  vertical-align: middle;
}
