.Wrapper {
  width: 982px;
  height: 160px;
  background-color: var(--cookies-bg);
  color: var(--cookies-content-color);
  align-self: center;
  padding: 1rem;
  h3 {
    margin: 0 0 0.5rem 0;
  }
  > p {
    a {
      color: var(--cookies-content-color);
      text-decoration: underline;
      &:hover {
        color: var(--cookies-content-color);
      }
      &:visited {
        color: var(--cookies-content-color);
      }
    }
  }
  > div {
    button {
      background: transparent;
      border: 2px solid var(--cookies-content-color);
      color: var(--cookies-content-color);
      padding: 0.5rem;
      font-size: 1rem;
      &:hover {
        cursor: pointer;
      }
      &:first-of-type {
        margin-right: 0.5rem;
      }
    }
  }
  @media (max-width: 973px) {
    width: 100%;
    h3 {
      font-size: 1rem;
    }
    > p {
      font-size: 0.8rem;
    }
    > div {
      button {
        font-size: 0.8rem;
      }
    }
  }
}
