.cloneSchemas {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 70vh !important;

  .switchDiv {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .switchTextInput {
      color: var(--main-font-color);
      font-size: 0.9em;
      font-weight: bold;
      margin-block-end: 0.5em;
      margin-block-start: 0.5em;
      margin-inline-end: 0px;
      margin-inline-start: 0px;
      padding: 10px !important;
    }
  }

  .filters {
    margin: 0rem;
  }

  .selectedDataflow {
    margin-top: auto;
    padding-top: 1em;

    &.filteredSelected {
      position: absolute;
      bottom: 0;
    }
  }

  :global .p-datatable-row {
    cursor: pointer;
  }
}
