.repRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 996px) {
  .repRow {
    flex-direction: column;
  }
}

@media (min-width: 480px) {
  .repRow {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (min-width: 768px) {
  .repRow {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (min-width: 996px) {
  .repRow {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@media (min-width: 1140px) {
  .repRow {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

.content {
  margin-top: 6px;
  .mainTextWrapper {
    display: flex;
    justify-content: space-between;
    background-color: var(--public-custom-header-bg);
    max-width: 100vw;
    :global .rep-container {
      max-width: 97%;
    }
    .pageContent {
      .frontText {
        color: var(--c-white-color);
        display: grid;
        grid-template-columns: 36% 49% 15%;
        grid-template-rows: 100%;
        .text {
          grid-column: 1/2;
          padding-top: 3rem;
          h2 {
            font-size: 1.2rem;
            color: var(--c-white-color);
            border-bottom: 1px solid var(--c-white-color);
            padding-bottom: 1.2rem;
          }
          .columnsWrapper {
            display: flex;
            justify-content: space-between;
            .column {
              width: 45%;
              line-height: 1.5rem;
              a {
                color: var(--c-white-color) !important;
                text-decoration: underline;
                &:hover {
                  color: var(--c-corporate-yellow) !important;
                }
                &:visited {
                  color: var(--c-corporate-yellow) !important;
                }
              }
            }
          }
        }
        .illustration {
          grid-column: 2/3;
          display: flex;
          justify-content: flex-end;
          margin-right: 2rem;
          img {
            max-width: 90%;
          }
        }
        .sideBar {
          grid-column: 3/4;
          display: flex;
          flex-direction: column;
          padding: 3rem 1rem;
          .contactBox {
            width: 100%;
            color: var(--c-white-color);
            .iconWrapper {
              .emailIcon {
                width: 25px;
                height: 25px;
                font-weight: lighter;
              }
            }
            h4 {
              font-size: 1rem;
              margin-top: 0px;
              margin: 0.2rem 0 0.6rem 0;
            }
            p {
              font-size: 0.9rem;
              margin: 0px;
            }
            a {
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: var(--c-white-color);
              height: 100%;

              p {
                margin: 0px;
              }
            }
          }
          .separator {
            background-color: var(--c-white-color);
            width: 90%;
            margin: 2.2rem 0;
          }
          .linkBox {
            display: flex;
            flex-direction: column;
            color: var(--c-white-color);
            font-size: 0.9rem;
            .iconWrapper {
              .emailIcon {
                width: 25px;
                height: 26px;
                font-weight: lighter;
              }
            }
            h4 {
              font-size: 1rem;
              margin-top: 0px;
              margin: 0.2rem 0 0.6rem 0;
            }
            ul {
              padding: 0 0 0 1rem;
              margin: 0;
              li {
                margin-top: 0.1rem;
                margin-bottom: 0.1rem;
                &:hover {
                  text-decoration: underline;
                }
                a {
                  display: flex;
                  align-items: center;
                  color: var(--c-white-color);
                  text-decoration: none;
                }
              }
            }
          }
        }
        @media (max-width: 1505px) {
          grid-template-columns: 80% 20%;
          .text {
            grid-row: 1/2;
            max-width: 80%;
          }
          .illustration {
            display: none;
          }

          .sideBar {
            grid-column: 2/3;
          }
        }
        @media (max-width: 1314px) {
          justify-content: space-between;
          display: flex;
          .text {
            width: 72%;
            padding-top: 2rem;
            h2 {
              margin-top: 0px;
            }
          }
          .illustration {
            display: none;
            img {
              display: none;
            }
          }
          .sideBar {
            width: 25%;
            padding: 2rem 0 0 2rem;
            margin: 0;
            flex-direction: column;
            .contactBox {
              margin-bottom: 0px;
            }
            .separator {
              display: block;
              margin: 1rem 0;
            }
            .linkBox {
              width: 100%;
            }
          }
        }
        @media (max-width: 903px) {
          flex-direction: column;
          display: flex;
          .text {
            width: 100%;
          }
          .illustration {
            display: none;
            img {
              display: none;
            }
          }
          .sideBar {
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            padding: 2rem 0;
            .contactBox {
              width: 55%;
              margin-bottom: 0px;
            }
            .separator {
              display: none;
            }
            .linkBox {
              width: 40%;
            }
          }
        }
        @media (max-width: 736px) {
          padding: 1rem;
          .text {
            .columnsWrapper {
              flex-direction: column;
              .column {
                width: 100%;
              }
            }
          }
          .sideBar {
            flex-direction: row;
            justify-content: space-between;
          }
        }
        @media (max-width: 320px) {
          padding: 1rem;
          .text {
            .columnsWrapper {
              flex-direction: column;
              .column {
                width: 100%;
              }
            }
          }
          .sideBar {
            flex-direction: column;
            justify-content: flex-start;
            .separator {
              display: block;
              margin: 2rem 0;
            }
          }
        }
      }
    }
  }

  .titleDataflowStatus,
  .titleExternalPortals {
    width: 100%;
    text-align: left;
  }

  .showPublicData,
  .portalList {
    display: flex;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    .showPublicDataButton,
    .portalBox {
      width: 45%;
    }
  }

  @media (max-width: 480px) {
    .showPublicDataButton,
    .portalBox {
      width: 100%;
    }
  }

  .otherPortals {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-left: 1rem;
    width: 48%;
    padding-bottom: 4rem;
    .portalList {
      justify-content: flex-start;
      .portalBox {
        flex-direction: column;
        border-radius: 5px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
        padding: 0.7rem 0.5rem 0rem 0.5rem;
        cursor: pointer;
        p {
          text-align: center;
        }
        &:hover {
          box-shadow: var(--card-item-box-shadow-hover);
          transform: scale(1.02, 1.02);
          transition: transform 0.2s ease;
        }
      }
    }
    @media (max-width: 624px) {
      .portalList {
        flex-direction: column;
        .portalBox {
          width: 100%;
          &:first-of-type {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .showPublicDataButton,
  .portalBox {
    width: 30%;
    margin: 0.5rem 0.8rem 0rem 0rem;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .showPublicBox {
    margin-top: 1rem;
    margin-right: 1rem;
    width: 48%;

    .showPublicData {
      justify-content: flex-start;

      .showPublicDataButton {
        background-color: var(--public-custom-header-bg);
        color: var(--c-white-color);
        position: relative;
        flex-direction: column;
        border-radius: 5px;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
        padding: 1.5rem 1.5rem 0.5rem 1.5rem;
        cursor: pointer;
        &::after {
          content: '';
          background-image: url('/views/_assets/images/logos/logo.png') !important;
          background-color: var(--card-item-bg);
          background-size: 50%;
          background-repeat: no-repeat;
          background-position: 150% 50%;
          bottom: 0;
          left: 0;
          opacity: 0.1;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          border-radius: 5px;
        }
        p {
          text-align: center;
          margin-bottom: 1rem;
          font-size: 1.1rem;
        }
        &:hover {
          box-shadow: var(--card-item-box-shadow-hover);
          transform: scale(1.02, 1.02);
          transition: transform 0.2s ease;
        }
        .showPublicDataButtonImg {
          opacity: 0.8;
        }
      }
    }
  }
}
