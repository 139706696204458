.header {
  overflow: hidden;
  background-color: var(--c-white-color);
  padding: 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
}
.header h1 {
  margin-bottom: 0;
  margin-top: 0;
  color: var(--c-blue-120);
}

.appLogo {
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}

.appTitle {
  margin: 10px;
}
/* Float sections to the right and left */

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .headerRight {
    float: none;
  }
}
