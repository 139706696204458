.actionTemplate {
  display: flex;
  justify-content: center;

  .tooltip {
    border-radius: 3px;
    padding: 1rem;
  }
}
.editRowButton,
.editSaveRowButton,
.editCancelRowButton {
  border: 1px solid var(--general-border-color) !important;
  margin-left: 3px !important;
  &:hover:enabled {
    border: 1px solid var(--button-secondary-border-color-hover) !important;
  }
}
