@import '_styles/mixins';

.ButtonsBar {
  margin-bottom: 0.5rem;
  padding: 0.25rem;

  .menuWrapper {
    width: 16rem;
  }
}
.descriptionLabel {
  margin: 0.5vw 0 0.5vw 0.25vw;
  color: var(--main-font-color);
}
.datasetDescription {
  width: 50vw;
  margin-bottom: 1rem !important;
  transition: 0.5s !important;
  @include for-desktop-up {
    flex: auto;
  }
}

.Dialog {
  width: 55vw;
  .checkboxWrapper {
    margin-top: 1rem;
    user-select: none;
    :global .p-checkbox {
      margin-left: 0 !important;
    }
    label {
      margin-left: 0.5rem !important;
      a {
        cursor: pointer;
        color: var(--main-font-color);
      }
    }
  }
}

.FileUpload .span {
  width: 100%;
  min-height: 120px;
}

.replaceDataLabel {
  cursor: pointer;
}

.warning > span {
  color: red;
}

:global .p-tabview.p-tabview-top {
  padding-bottom: 80px;
}

.titleWrapper {
  margin-bottom: 1rem;
}

.stepProgressBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.switchDivInput {
  display: flex;
  align-items: center;
  justify-items: flex-end;
  flex-direction: row;
}

.datasetDescriptionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5vw 0;

  .datasetConfigurationButtons {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
    > div {
      display: flex;
      margin-right: 0.5rem;
    }
  }
}

.switchDiv {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.switchTextInput {
  font-size: 0.9em;
  font-weight: bold;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 5px;
  color: var(--main-font-color);
}

:global body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  @include shadow-0em;
}

.noScrollDatasetDesigner {
  overflow: hidden !important;
}

.integrationsButtonIcon {
  transform: rotate(-90deg) !important;
}

.integrationsButton:hover span:first-child:not(:hover) {
  transform: rotate(-90deg) scale(1.25) !important;
  transition: 0.08s auto;
}

.integrationsButton span:first-child:hover {
  transform: rotate(-90deg) scale(1.25) !important;
  transition: 0.08s auto;
}

.validationsFooter {
  display: flex;
  justify-content: space-between;
}

.deleteDatasetFooter {
  float: left !important;
}

.checkboxWrapper {
  float: left !important;
  margin-top: 1rem;
  :global .p-checkbox {
    margin-left: 0 !important;
  }
  label {
    margin-left: 0.5rem !important;
    user-select: none;
    cursor: pointer;
    span {
      color: var(--main-font-color);
    }
    span:hover {
      color: var(--main-font-color);
    }
  }
}

.reloadWebformOptionsDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qcDialogFooterWrapper {
  display: flex;
}

.buttonAlignRight {
  margin-left: auto !important;
}

.adminCloseButton {
  margin-left: auto !important;
}

.closeButton {
  margin-left: 6px !important;
}
