.checkedValueColumn {
  text-align: center;

  .icon {
    color: var(--main-font-color);
  }
}

.dialogContent,
.noDataContent {
  height: 58vh;
}

.dialogWidth {
  min-width: 60vw;
}

.loadingSpinner {
  height: 58vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinnerPosition {
    top: 0;
    left: 0;
  }
}

.noDataContent {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  flex-direction: column;
  button {
    padding: 0.5em 1em;
  }
}
