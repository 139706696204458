.container {
  height: 55vh;
}

.datasets {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.emptyFilteredData,
.noDatasets {
  height: 75%;
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}
