.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1rem 0;
  user-select: none;

  .group {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 1.5rem;

    &:nth-last-child(2) {
      align-items: flex-end;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .field {
      :global .p-dropdown {
        padding-bottom: 3px;
      }

      --fieldGap: 1rem;
      --fieldWidth: 285px;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 0;

      > label {
        margin-bottom: 0.2rem;
      }

      &.name {
        width: var(--fieldWidth);
      }

      &.description {
        width: calc(100% - (var(--fieldWidth)) - var(--fieldGap));
      }

      &.operation {
        width: 49%;
      }
      &.repository,
      &.fileExtension,
      &.processName {
        width: 49%;
      }

      &.fileExtensionNotification {
        width: 32% !important;
      }

      &.notificationRequired {
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        .checkboxWrapper {
          display: flex;
          align-items: center;
          height: 100%;
        }
      }

      &.description,
      &.fileExtension,
      &.processName {
        margin-right: 0;
      }

      &.parameterKey,
      &.parameterValue {
        width: 44%;
      }
    }

    .parameters {
      height: 250px;
      outline-offset: 0;
      outline: 0 none;
      position: relative;
      width: 100%;

      .list {
        height: 250px;
        margin: 0;
        overflow: auto;
        padding: 0;
        position: relative;
        width: 100%;
        border: var(--inputtext-border);
        border-radius: 5px;

        .item {
          align-items: center;
          border-bottom: var(--external-integration-item-border);
          cursor: pointer;
          display: flex;
          float: left;
          justify-content: space-between;
          list-style: none;
          margin: 0;
          padding: 0.5rem 1rem;
          width: 100%;

          &.selected {
            background-color: var(--external-integration-item-bg-color-selected);
          }

          &:hover {
            background-color: var(--external-integration-item-bg-color-hover);
          }

          .key,
          .value {
            align-items: center;
            display: flex;
            font-weight: bold;
            width: 45%;

            .parameterText {
              font-weight: normal;
              margin-left: 1rem;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 65%;
            }

            > input {
              width: auto;
            }
          }
        }
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}
