.inputswitch_dark_theme_checked:before {
  background-color: var(--c-black-color) !important;
  box-shadow: inset -4px 0px 1px 0.5px var(--c-white-color) !important;
}

.inputswitch_dark_theme_unchecked,
.inputswitch_dark_theme_checked {
  margin-right: -0.15em !important;
}

.inputswitch_dark_theme_checked {
  background-color: var(--c-black-color) !important;
}

.inputswitch_dark_theme_unchecked:before {
  background-color: var(--c-corporate-yellow) !important;
  box-shadow: inset -3px 0px rgba(0, 0, 0, 0.1) !important;
}
