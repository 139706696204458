body .p-inputswitch {
  width: 3em;
  height: 1.75em;
}
body .p-inputswitch .p-inputswitch-slider {
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
  background: var(--inputswitch-unchecked-bg);
}
body .p-inputswitch .p-inputswitch-slider:before {
  background-color: var(--inputswitch-slider-bg);
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
  background-color: var(--inputswitch-checked-before-bg);
}
body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #004494;
  -moz-box-shadow: 0 0 0 0.2em #004494;
  box-shadow: 0 0 0 0.2em #004494;
}
body .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: #b7b7b7;
}
body .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  min-width: 42px;
  background-color: var(--inputswitch-checked-bg);
}
body .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: #116fbf;
}
