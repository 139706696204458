@import '_styles/mixins';

.noDataContent {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  flex-direction: column;
  .spinnerPosition {
    top: 0;
    left: 0;
  }
}

.dialog {
  width: 50vw !important;
  > div:nth-child(2) > div:first-child {
    margin: 0 0 2.5em 0 !important;
  }
  > div:nth-child(2) > div:first-child > div {
    margin: 0 !important;
  }
}

.dialogContent {
  table > tbody > tr > td:last-child {
    text-align: center;
  }
}

.buttonsDialogFooter {
  display: flex;
  justify-content: space-between;
}

.actionButton {
  border: 1px solid var(--general-border-color) !important;
  @include shadow-0em;
  margin-left: 3px !important;
  &:enabled:hover {
    border-color: var(--button-secondary-border-color-hover) !important;
  }
}

.deleteButton {
  @extend .actionButton;
  color: var(--errors) !important;
}
