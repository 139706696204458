.webform {
  margin-bottom: 1rem;

  .toolbar {
    font-size: 1rem;
    font-weight: 600;

    .headerButton {
      border-radius: 5px;
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.spinner {
  top: 0;
}
