.p-dropdown-items-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.p-dropdown-items-wrapper::-webkit-scrollbar-track {
  background: var(--bg);
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb {
  background: var(--c-gray-75);
}

.p-dropdown-items-wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--c-gray-50);
}

.overwriteZindexPanel {
  z-index: 3000 !important;
}
