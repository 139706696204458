.loginBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--c-blue-120);
}
.loginBoxContainer .loginBox {
  background-color: var(--c-white-color);
  padding: 1rem;
  border-radius: 5px;
  width: 400px;
}
.loginBoxContainer .loginBox .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
.loginBoxContainer .loginBox .logo h1 {
  font-size: var(--heading-1);
}
.loginBoxContainer .loginBox .logo img {
  width: 50px;
}
.loginBoxContainer .loginBox form fieldset {
  border: none;
}
.loginBoxContainer .loginBox form fieldset label {
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.loginBoxContainer .loginBox form fieldset input {
  width: 100%;
  padding: 0.5rem;
  display: block;
  font-size: 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  box-sizing: border-box;
}
.loginBoxContainer .loginBox form fieldset.buttonHolder {
  text-align: center;
}

:global .rp-btn {
  width: 100%;
  border-radius: 5px;
}

.error {
  padding: 1rem;
  border: 2px solid var(--errors);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--errors);
  margin: 1rem;
}

.hasErrors {
  border: 2px solid var(--errors) !important;
  &:focus {
    box-shadow: none !important;
  }
}
