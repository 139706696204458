:global .p-accordion .p-accordion-content {
  padding: 1rem;
  margin: auto;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 0;
  margin: 0;
}
.listItem {
  display: flex;
  color: var(--main-font-color);
  background-color: var(--bg);
  padding: 5px;
  margin: 0;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
