#mainLayoutContainer {
  min-height: calc(100vh - var(--header-height));
}
.mainContent {
  background-color: var(--bg);
  display: flex;
}
.pageContent {
  transition: all 500ms ease 0ms;
  width: 100%;
}
.disclaimer {
  padding: 0.5em;
  line-height: 1.5;
  background-color: #f8b7bd;
  color: #212121;
  text-align: center;
  font-weight: 700;
}
