.titleBar {
  display: flex;
  border-bottom: 1px solid var(--c-gray-25);
  margin: 20px 0;
  justify-content: space-between;
  .title_wrapper {
    .title {
      margin: 0;
      color: var(--main-font-color);
    }
  }
}

.dialog :global .p-dialog-content {
  padding-bottom: 0 !important;
}

.buttonsRolesFooter {
  display: flex;
  justify-content: space-between;
}
