.listItem {
  display: flex;
  color: var(--main-font-color);
  background-color: var(--bg);
  padding: 5px;
  margin: 0;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.labelItem {
  margin: 0.3rem;
  margin-left: 0rem;
  padding-left: 1rem;
  cursor: pointer;
}
.checkbox {
  position: relative;
  top: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  border: transparent;
  display: inline-flex;
  flex-direction: row;
  max-width: 1rem;
  align-items: center;
}

.checkbox:focus {
  border-color: transparent;
  box-shadow: none;
}
.checkbox::before {
  content: '';
  position: absolute;
  left: 0;
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--c-gray-50);
  z-index: 1001;
  transition: all 0.3s ease-in-out;
}
.checkbox:checked::before {
  height: 0.5rem;
  border-color: var(--accent-color);
  border-top-style: none;
  border-right-style: none;
  transform: rotate(-45deg);
}
.checkbox::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
