@import '_styles/mixins';

.buttonWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cancelButton {
  margin-left: 0.5rem !important;
}
.crudColumn {
  text-align: center;
  width: 7em;
}
.deleteRowButton,
.editRowButton {
  border: 1px solid var(--general-border-color) !important;
  margin-left: 3px !important;
  @include shadow-0em;
  &:hover {
    border: 1px solid var(--button-secondary-border-color-hover) !important;
  }
}
.dialog {
  height: auto !important;
}
.deleteRowButton {
  color: var(--errors) !important;
}
.iconStyle {
  text-align: center;
}
.noDataWrapper {
  display: flex;
  justify-content: center;
  h4 {
    font-style: italic;
  }
}
.webLinkEditButtons {
  display: flex;
  justify-content: center;
}

.webLinksToolbar {
  border: 0px !important;
  margin-bottom: 0.5rem;
}

.checkboxIsPublic {
  display: flex;
  align-items: center;
  input {
    width: auto;
    border: var(--checkbox-box-border);
    background-color: var(--checkbox-box-bg);
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }
  input:checked {
    border-radius: 3px;
  }
  input:focus {
    border-radius: 3px;
  }
  label {
    margin-left: 0.5rem;
    font-weight: bold;
    cursor: pointer;
  }
}
