.message {
  max-width: 50%;
  background: #ebebeb;
  margin: 10px 0 3px 25px;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.2);
  display: flex;
  .deleteMessageButton {
    background-color: var(--c-corporate-blue);
    border-radius: 12px;
    border: 2px solid var(--c-corporate-blue);
    color: var(--c-white-color);
    cursor: pointer;
    height: 1.25rem;
    left: 6px;
    min-height: 1.25rem;
    min-width: 20px;
    position: relative;
    top: -6px;
    width: 16px;
  }
  &.sender {
    border-radius: 10px 0px 10px 10px;
    margin: 10px 25px 3px 0px;
    background: var(--c-corporate-blue);
    align-self: flex-end;
    &.automatic {
      background-color: var(--automatic-message-sender-bg) !important;
      .messageTextWrapper {
        .messageText {
          color: var(--automatic-message-text);
        }
        .datetime {
          color: var(--automatic-message-text);
        }
      }
    }
    .messageTextWrapper {
      .messageText {
        color: var(--c-white-color);
      }
      .datetime {
        color: var(--c-gray-5);
      }
      .messageAttachment {
        background-color: var(--c-corporate-blue-lighter);
        border-radius: 6px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        max-width: 30rem;
        span {
          color: var(--c-white-color);
        }
        .downloadFileButton {
          margin-left: auto;
          &:hover {
            background-color: transparent;
          }
          span {
            &:first-child {
              display: none;
            }
            &:nth-child(2) {
              font-size: 18pt;
            }
          }
        }
        .messageAttachmentFile {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          div {
            span {
              font-size: 10pt;
            }
          }
          svg {
            color: var(--c-white-color);
            font-size: 16pt;
          }
        }
        .messageAttachmentFileData {
          margin-top: 0.5rem;
          font-weight: bold;
          span {
            color: var(--c-gray-15);
            font-size: 8pt;
            font-style: italic;
          }
        }
      }
    }
    @media (max-width: 468px) {
      margin-right: 10px;
    }
  }
  &.receiver {
    align-self: flex-start;
    border-radius: 0 10px 10px 10px;
    margin: 10px 0 3px 25px;
    color: var(--c-white-color);
    background: var(--c-lilac-300);
    &.automatic {
      background-color: var(--automatic-message-receiver-bg) !important;
      .messageTextWrapper {
        .messageText {
          color: var(--automatic-message-text);
        }
        .datetime {
          color: var(--automatic-message-text);
        }
      }
    }
    .messageTextWrapper {
      .messageText {
        color: var(--c-white-color);
      }
      .datetime {
        color: var(--c-gray-5);
      }
      .messageAttachment {
        background-color: var(--c-lilac-200);
        border-radius: 6px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        width: fit-content;
        max-width: 30rem;
        span {
          color: var(--c-lilac-400);
        }
        .downloadFileButton {
          margin-left: auto;
          &:hover {
            background-color: transparent;
          }
          span {
            &:first-child {
              display: none;
            }
            &:nth-child(2) {
              font-size: 18pt;
            }
          }
        }
        .messageAttachmentFile {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          div {
            span {
              font-size: 10pt;
            }
          }
          svg {
            color: var(--c-lilac-400);
            font-size: 16pt;
          }
        }
        .messageAttachmentFileData {
          margin-top: 0.5rem;
          font-weight: bold;
          span {
            color: var(--c-lilac-400);
            font-size: 8pt;
            font-style: italic;
          }
        }
      }
    }
    @media (max-width: 468px) {
      margin-left: 10px;
    }
  }

  .messageTextWrapper {
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    .messageText {
      font-size: 0.9rem;
      font-weight: 500;
      padding-top: 0.5rem;
      word-break: break-all;
    }
    .datetime {
      font-style: italic;
      font-size: 0.7rem;
      text-align: right;
      white-space: nowrap;
      margin-left: 1.5rem;
    }
  }
  .bubbleArrow {
    position: absolute;
    float: left;
    left: -11px;
    top: 0px;
    &.sender {
      bottom: 20px;
      left: auto;
      right: 4px;
      float: right;
    }
  }
  .bubbleArrow:after {
    content: '';
    position: absolute;
    border-top: 15px solid #ebebeb;
    border-left: 15px solid transparent;
    border-radius: 4px 0 0 0px;
    width: 0;
    height: 0;
  }
  .bubbleArrow.sender:after {
    border-top: 15px solid #dcf8c6;
    transform: scaleX(-1);
  }
  @media (max-width: 468px) {
    max-width: 85%;
  }
}

.unreadSeparator {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1rem 10rem;
  font-style: italic;
  font-weight: bold;
  color: var(--feedback-separator-color);
}
.unreadSeparator::before,
.unreadSeparator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--main-font-color);
}
.unreadSeparator::before {
  margin-right: 0.25em;
}
.unreadSeparator::after {
  margin-left: 0.25em;
}
