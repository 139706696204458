.noObligations {
  color: var(--button-secondary-transparent-color-hover);
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.checkColumn {
  text-align: center;
}

.titleColumn {
  display: flex;
  justify-content: space-between;

  .linkIcon {
    color: var(--main-font-color);
    cursor: pointer;
    float: center;
  }
}

.cursorPointer {
  cursor: pointer;
}
