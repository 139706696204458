@import '_styles/fonts';
@import '_styles/cssVariables';
@import '_styles/general';
@import '_styles/primeReact';
@import '_styles/grid';
@import '_styles/buttons';
@import '_styles/text';
@import '_styles/forms';
@import '_styles/components';
@-ms-viewport {
  width: device-width;
}
