.feedbackWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding-top: 1rem;
  .dataProviderWrapper {
    margin: 0;
    margin-bottom: 0.5rem;
    vertical-align: middle;
    display: flex;
    @media (min-width: 590px) {
      margin-bottom: 0;
    }
    .dataProvider {
      width: 100%;
      :global .p-listbox-title-wrapper {
        overflow: hidden;
      }
      @media (min-width: 590px) {
        width: 220px;
      }
    }
    .hasCounter {
      margin-bottom: 2.5rem;
      @media (min-width: 590px) {
        margin-bottom: 0;
      }
    }
    span {
      color: var(--main-font-color);
      font-weight: bold;
    }
  }
  .dragAndDropFileMessage {
    position: absolute;
    top: 50%;
    left: 45%;
    color: var(--feedback-drag-color);
    opacity: var(--drag-and-drop-div-low-opacity);
    font-size: 18pt;
    font-weight: bold;
  }
  .listMessagesWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 590px) {
      margin-left: 0.5rem;
    }
    .sendMessageWrapper {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      margin-top: 0.5rem;
      width: 100%;
      textarea {
        height: 78px !important;
      }
      @media (min-width: 590px) {
        flex-direction: column;
        flex-wrap: unset;
      }
      @media (min-width: 960px) {
        flex-direction: row;
      }
      .sendMessageTextarea {
        overflow: hidden;
        &:disabled {
          border: 1px solid var(--c-gray-50);
        }
      }
      .buttonsWrapper {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        display: flex;
        align-items: flex-end;
        justify-content: right;
        @media (min-width: 960px) {
          margin-top: 0;
          width: 300px;
          align-self: center;
        }
        @media (min-width: 1310px) {
          width: 260px;
        }
      }
      .sendMessageButton {
        margin-left: 0.5rem;
        align-self: flex-start;
        @media (max-width: 320px) {
          :global .pi-comment {
            display: none;
          }
        }
      }
      .attachFileMessageButton {
        align-self: flex-start;
      }
    }
    .spinnerLoadingMessages {
      top: 0 !important;
      left: 0 !important;
    }
  }
  @media (min-width: 590px) {
    flex-direction: row;
  }
}
.helpdeskMessage {
  color: var(--main-font-color);
  font-style: italic;
  font-weight: bold;
  margin: 1rem;
}

.messageCounter {
  user-select: none;
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  top: -2rem;
  background-color: var(--statusBox-info-bg);
  color: var(--statusBox-info-color);
  border-radius: 3px;
  padding: 4px;
}
