@import '_styles/mixins';

.groupExpression {
  list-style: none;
  > ul {
    list-style: none;
    padding-left: 0;
    .expression {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .groupRow {
        display: flex;
        justify-content: flex-start;
        > span {
          margin-right: 1rem;
          &:first-of-type {
            margin-right: 1.5rem;
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
        .group {
          margin-right: 2rem;
          :global .p-checkbox-box {
            width: 29px !important;
            height: 29px !important;
            :global .p-checkbox-icon {
              font-size: 27px;
            }
          }
        }
        .union {
          width: 80px;
          :global .p-dropdown {
            width: 100%;
          }
        }
        .groupToggler {
          color: var(--main-font-color);
        }
        .deleteButton {
          border: 1px solid var(--c-custom-gray) !important;
          @include shadow-0em;
          color: var(--errors) !important;
          margin: 0 !important;
        }
      }
      .groupExpressions {
        > span {
          > ul {
            padding-left: 0px;
            margin-left: 2.8rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
