.dropdownFilter {
  position: absolute;
  cursor: pointer;
  font-size: 0.8rem;
  border: var(--dropdownFilter-border);
  background-color: var(--dropdownFilter-bg-color);
  min-width: 250px;
  max-height: 27.5vh;
  overflow-y: auto;
  border-radius: 2%;
  z-index: 1000;
  padding: 0 0.8rem;
  color: var(--dropdownFilter-color);
  box-shadow: var(--dropdownFilter-box-shadow);
  font-size: 0.9rem;
  opacity: 0;
  user-select: none;
  ul {
    list-style: none;
    padding: 0px;
    li {
      padding: 0.2rem;
      a {
        color: var(--c-black-400);
        &:hover {
          color: var(--c-black-400);
          background-color: rgba(0, 0, 0, 0);
        }
        svg {
          margin-right: 0.5rem;
        }
      }
      &:last-child {
        margin-bottom: 0.5rem;
      }
    }
  }
  .dropdownFilterIcon {
    margin-right: 0.5rem !important;
  }

  .isNotChecked {
    color: var(--c-black-300) !important;
  }
  .selectNone {
    display: flex;
    user-select: none;
    &:hover {
      background-color: var(--dropdownFilter-border-color-hover);
    }
  }
  .checkboxIcon {
    font-size: 14pt;
    color: var(--main-font-color);
  }
}
