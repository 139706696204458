@import '_styles/mixins';

.validations {
  height: 75vh;
}

.validationsWithoutTable {
  height: 75vh;
}

.emptyFilteredData,
.noValidations {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.checkboxEditorWrapper {
  display: flex;
  justify-content: center;
  .checkboxEditor {
    width: 20px !important;
  }
}
.emptyFilteredData {
  height: 75%;
}

.noValidations {
  height: 100%;
}

.loadingSpinner {
  height: 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinnerPosition {
    top: 0;
    left: 0;
  }
}

.positioning {
  position: sticky !important;
}

.tabsValidations {
  margin: 0.5em 0;
}

.searchInput {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 0 0 1rem 0;

  > span {
    margin-right: 2rem;
  }
}

.checkedValueColumn {
  text-align: center;

  .icon {
    color: var(--main-color-font);
  }

  .spinner {
    animation: spinnerAnimation 0.75s linear infinite;
  }
}

.paginatorValidationViewer :global .p-dropdown-panel {
  top: -6rem !important;
}

.levelErrorTemplateWrapper {
  display: flex;
  justify-content: center;
}

@keyframes spinnerAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.actionButton {
  border: 1px solid var(--general-border-color) !important;
  @include shadow-0em;
  margin-left: 3px !important;
  &:enabled:hover {
    border-color: var(--button-secondary-border-color-hover) !important;
  }
}
.deleteRowButton,
.invalidSqlIcon {
  @extend .actionButton;
  color: var(--errors) !important;
}

.expressionsTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sqlSentenceCostTemplate {
  display: flex;
  justify-content: center;
}

.descriptionColumn {
  width: 23%;
}

.entityTypeColumn,
.levelErrorColumn {
  min-width: 6rem;
}
