.buttonWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cancelButton {
  margin-left: 0.5rem !important;
}

.checkboxIsPublic {
  display: flex;
  align-items: center;
  input {
    width: auto;
    border: var(--checkbox-box-border);
    background-color: var(--checkbox-box-bg);
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 3px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }
  input:checked {
    border-radius: 3px;
  }
  input:focus {
    border-radius: 3px;
  }
  label {
    margin-left: 0.5rem;
    font-weight: bold;
    cursor: pointer;
  }
}

.dropdownWrapper {
  width: 100%;
}
