.chooseReferenceDataflowText {
  position: absolute;
  top: 50%;
  width: 96%;
  text-align: center;
}

.referenceDataflowsWrapper {
  height: 22rem;

  .referenceDataflowsDropdownTitle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1.2rem;
    margin-top: 0.5rem;
  }

  .selectedReferenceDataflowsDropdownTitle {
    justify-content: space-between;
  }

  .referenceDataflowsDropdownWrapper {
    > label {
      margin-right: 0.5rem;
      font-weight: bold;
    }

    .referenceDataflowsDropdown {
      width: 20rem;
    }
  }
}

.positioning {
  top: 0 !important;
}

.referenceDataflowSchemaWrapper {
  padding: 0rem !important;
}

.schemaWrapper {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding: 1rem 0 0 0;
  > div {
    min-width: 25%;
    max-width: 50%;
    margin-bottom: 1rem;
    flex-grow: 1;
    box-shadow: 0 1px 0.2rem rgba(0, 0, 0, 0.6);
  }
  > div:not(:last-child) {
    margin-right: 1rem;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: var(--bg);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--c-gray-75);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--c-gray-50);
  }
  :global .p-listbox-title-wrapper {
    overflow: hidden;
  }
}
.fieldSelector {
  margin: 0 1rem;
}
.selectedLinkWrapper {
  margin-top: 1rem;
}
.selectedReferenceDataflowLabel {
  font-weight: bold;
}

.selectedLinkLabel {
  font-weight: bold;
  font-style: italic;
}
.selectedLinkFieldsWrapper {
  margin-top: 1rem;
}
.selectedLinkFieldsWrapper span {
  font-weight: bold;
}

.switchTextInput {
  font-weight: bold;
}

.infoButton {
  background-color: var(--c-white-color) !important;
  border-radius: 50% !important;
  color: var(--datatable-header-info-button-color) !important;
  font-size: 12pt !important;
  height: 0.7em !important;
  margin-right: 0.6rem !important;
  width: 0.7em !important;

  > span {
    left: 46% !important;
    margin-left: -0.48em !important;
    margin-top: -0.5em !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}
