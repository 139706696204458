.footer {
  display: flex;
  border: solid var(--c-corporate-blue);
  border-width: 8px 0;
  .footerContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 auto;
    padding: 0 4rem;
    background-color: var(--footer-content-bg);
    .reportnetLogo {
      a {
        display: flex;
        align-items: center;
        h1 {
          margin-left: 0.5rem;
          color: var(--c-corporate-blue);
        }
      }
    }
    .helpDesk {
      a {
        font-weight: bold;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin: 1rem 0;
      .reportnetLogo {
        &:nth-of-type(1) {
          img {
            width: 200px;
            height: auto;
          }
        }
      }
    }
  }
  .appTitle {
    color: var(--app-title-color) !important;
  }
}
