@import '_styles/mixins';

.noObligations {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
}

.currentPageWrapper {
  color: var(--c-white-color);
  margin-left: 1.5rem;
  user-select: none;
  .currentPageInput {
    display: inline;
    height: 1.75rem;
    width: 2.5rem;
  }
  .currentPageLabel {
    margin: 0 0.5rem;
  }
  .currentPageOf {
    margin: 0 0 0 0.5rem;
  }
}

.cardWrap {
  display: grid;
  grid-gap: 1em;
  margin-bottom: 2rem;
  grid-template-columns: minmax(80%, 1fr);

  @media (min-width: 650px) {
    grid-template-columns: repeat(2, minmax(45%, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(20%, 1fr));
  }

  @media (min-width: 1500px) {
    grid-template-columns: repeat(5, minmax(15%, 1fr));
  }
}
