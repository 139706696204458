.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2rem 0;
  .content {
    display: flex;
    justify-content: space-between;
    .helpSideBar {
      width: 30%;
    }
    .sqlSentence {
      margin-top: -1rem !important;
      display: flex;
      flex-direction: column;
      width: 66%;
      .title {
        font-size: 1rem;
        color: var(--title-color);
        margin-bottom: 0.6rem;
        .sqlSentenceInfoBtn {
          background-color: var(--c-white-color) !important;
          border-radius: 50% !important;
          color: var(--datatable-header-info-button-color) !important;
          font-size: 12pt !important;
          height: 0.7em !important;
          width: 0.7em !important;
          margin-left: 0.4rem !important;
          margin-right: 0.9rem !important;
          margin-top: -1rem !important;
          :global .pi-info-circle {
            padding: 0 !important;
            top: 40%;
          }
          > span {
            left: 46% !important;
            margin-left: -0.48em !important;
            margin-top: -0.5em !important;
          }
          &:focus {
            box-shadow: none !important;
          }
        }

        .ccButton,
        .runButton,
        .validateButton,
        .sqlSentenceCostWrapper {
          margin-top: -0.9em;
          margin-left: 0.4rem !important;
          float: right;
        }
        .ccButton,
        .runButton,
        .validateButton {
          border: 1px solid var(--c-custom-gray) !important;
        }
        .ccButton {
          padding: 0.37rem 0.45rem;
        }
        .validateSqlSentenceIcon {
          padding-top: 1px;
        }
      }
      textarea {
        flex: auto;
      }
    }
  }
}
.levelHelp {
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--main-font-color);
}
.note {
  font-style: italic;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--main-font-color);
}

.trafficLightSize {
  width: 5rem;
  height: 2rem;
  margin-top: 0.2rem !important;
  > div {
    height: 1rem;
    width: 1rem;
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 2rem;
  .spinner {
    top: 3px;
    height: 25px;
    width: 25px;
  }
}

.validationDialogMaxWidth {
  width: 90% !important;
}

.noDataMessage {
  text-align: center;
}

.errorSectionWrapper {
  display: flex;
  justify-content: space-between;
}

.hasError {
  border-color: var(--errors) !important;
  box-shadow: var(--inputtext-box-shadow-error) !important;
}

.emptySqlErrorMessage {
  height: 1.2rem;
}

.errorSpacer {
  min-width: 30%;
}

.sqlErrorMessage {
  font-style: italic;
  font-size: 0.9em;
  color: var(--errors);
}

.emptySqlErrorMessage,
.sqlErrorMessage {
  margin-top: 0.5em;
  width: 66%;
}

.IsViewUpdatedMessage {
  font-style: italic;
  padding: 10px 0;
  p {
    margin: 0;
  }
}
