@import '_styles/mixins';

.container {
  min-height: 25vh;
  position: relative;
  .spinner {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    width: 100%;
    overflow: hidden !important;
    user-select: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9000 !important;

    svg {
      width: 100px;
    }
  }
}
.isValidUserIcon {
  left: 2px;
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
  top: 10px;
  cursor: pointer;
  color: var(--isValid-user-icon-color);
  font-size: 1.1rem;
}
.listContainer {
  padding-left: 0px;
  list-style: none;
}
.inputWrapper {
  display: flex;
}
.inputWrapperMargin:not(:last-child) {
  margin-bottom: 0.5rem;
}

.dataProvidersDropdown {
  max-width: 16rem;
}

.deleteButton {
  border: 1px solid var(--general-border-color) !important;
  @include shadow-0em;
  color: var(--errors) !important;
  margin-left: 3px !important;

  &:focus {
    box-shadow: none;
  }
  &:hover {
    border-color: var(--button-secondary-border-color-hover) !important;
  }
}

.selectWrapper {
  display: flex;
  justify-items: center;
  align-items: center;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.6rem;
}

.addDataProviderButtonWrapper :global .formField {
  width: 42%;
}
.title {
  font-weight: 600;
}
.chooseRepresentative {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.selectDataProvider {
  color: var(--dropdown-panel-color);
  background-color: var(--dropdown-panel-bg);
  &:hover:enabled {
    border-color: var(--dropdown-border-color-hover);
  }
}

.disabled {
  color: #5f5f5f !important;
}

.emptyTableHeader {
  :global .p-column-title {
    display: none;
  }
}
.emptyFilteredData {
  margin-top: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem !important;
  font-weight: bold;
}

.hasErrors {
  border: 1px solid var(--errors) !important;
  box-shadow: inset 0px 0px 0px 1px var(--errors);
  &:focus {
    box-shadow: none !important;
  }
}
