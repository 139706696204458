/* buttons */
.rp-btn {
  border-radius: 5px;
  border: 2px solid transparent;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: var(--c-white-color);
  cursor: pointer;
}

.rp-btn.default {
  color: var(--c-white-color);
}
.rp-btn.default:hover {
  background-color: var(--c-orange-200);
  color: var(--c-blue-120);
  border: 2px solid var(--c-orange-200);
}

.rp-btn.primary {
  background-color: var(--c-corporate-blue);
  border-color: var(--c-corporate-blue);
  border: 2px solid transparent;
  box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: var(--c-white-color);
  cursor: pointer;
}
.rp-btn.primary:hover {
  background-color: var(--c-orange-200);
  color: var(--c-blue-120);
  border: 2px solid var(--c-orange-200);
}

.rp-btn.success {
  background-color: var(--c-green);
}
.rp-btn.success:hover {
  background-color: var(--c-khaki-300);
}
.rp-btn.warning {
  background-color: var(--c-tile);
  color: var(--c-white-color);
}
.rp-btn.warning:hover {
  background-color: var(--c-orange-400) !important;
  border: 1px solid var(--c-orange-400) !important;
  color: var(--c-white-color) !important;
}

@keyframes p-progress-spinner-color {
  0% {
    stroke: var(--c-blue-120);
  }
  25% {
    stroke: var(--c-corporate-yellow);
  }
  50% {
    stroke: var(--c-blue-120);
  }
  100% {
    stroke: var(--c-corporate-yellow);
  }
}
