.globan {
  width: 100%;
  height: 28px;
  padding-left: 1rem;
  background-color: var(--eu-header-bg);
  .globanContent {
    color: var(--c-white-color);
    height: 28px;
    line-height: 22px;
    font-weight: normal;
    font-size: 0.87rem;
    &::before {
      content: '\00a0' !important;
      display: inline-block;
      width: 24px;
      height: 16px;
      background: center center / 110% auto no-repeat #004494
        url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='no'%3F%3E%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%0A version='1.1'%0A viewBox='0 0 810 540'%0A width='810' height='540'%3E%0A %3Cdesc%3EEuropean flag%3C/desc%3E%0A %3Cdefs%3E%0A %3Cg id='s'%3E%0A %3Cg id='c'%3E%0A %3Cpath id='t' d='M0%2C0v1h0.5z' transform='translate(0%2C-1) rotate(18)'/%3E%0A %3Cuse xlink:href='%23t' transform='scale(-1%2C1)'/%3E%0A %3C/g%3E%0A %3Cg id='a'%3E%0A %3Cuse xlink:href='%23c' transform='rotate(72)'/%3E%0A %3Cuse xlink:href='%23c' transform='rotate(144)'/%3E%0A %3C/g%3E%0A %3Cuse xlink:href='%23a' transform='scale(-1%2C1)'/%3E%0A %3C/g%3E%0A %3C/defs%3E%0A %3Crect fill='%23039' width='810' height='540'/%3E%0A %3Cg fill='%23fc0' transform='scale(30)translate(13.5%2C9)'%3E%0A %3Cuse xlink:href='%23s' y='-6'/%3E%0A %3Cuse xlink:href='%23s' y='6'/%3E%0A  %3Cg id='l'%3E%0A %3Cuse xlink:href='%23s' x='-6'/%3E%0A %3Cuse xlink:href='%23s' transform='rotate(150)translate(0%2C6)rotate(66)'/%3E%0A %3Cuse xlink:href='%23s' transform='rotate(120)translate(0%2C6)rotate(24)'/%3E%0A  %3Cuse xlink:href='%23s' transform='rotate(60)translate(0%2C6)rotate(12)'/%3E%0A  %3Cuse xlink:href='%23s' transform='rotate(30)translate(0%2C6)rotate(42)'/%3E%0A %3C/g%3E%0A %3Cuse xlink:href='%23l' transform='scale(-1%2C1)'/%3E%0A %3C/g%3E%0A%3C/svg%3E");
      float: left;
      margin: 5.5px 8px 0 0;
      border: 1px solid #7f99cc;
    }
    > span {
      cursor: pointer;
      font-weight: normal;
      text-decoration: none;
      color: var(--white-font);
      background-color: transparent;
      font-weight: 400;
      margin: 0 0 0 6px;
      padding: 0 7px;
      position: relative;
      display: inline-block;
      border: 3px solid rgba(255, 255, 255, 0);
    }
    .ban {
      font-size: 14px;
      position: relative;
      top: 0px;
      height: calc(64px + 2rem);
      background-color: var(--help-modal-bg);
      padding: 12px 16px;
      max-width: 500px;
      border: var(--dialog-content-border);
      box-shadow: var(--eu-header-shadow);
      -webkit-box-shadow: var(--eu-header-shadow);
      -moz-box-shadow: var(--eu-header-shadow);
      color: var(--main-font-color);
      p {
        margin-bottom: 0.5rem;
        a {
          color: var(--hyperlink-color);
          text-decoration: none;
        }
      }
    }
  }
  @media (max-width: 414px) {
    .globanContent {
      &::before {
        display: none;
      }
      span {
        display: none;
      }
      a {
        display: none;
      }
    }
  }
}
.euHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--bg);
  box-shadow: var(--header-box-shadow);
  .europeanUnionLogo {
    a {
      height: 56px;
      width: calc(320px - 32px);
      display: block;
      span {
        display: none;
      }
    }
    .europeanUnionBlackLogo {
      background-repeat: no-repeat;
      background-image: url('/views/_assets/images/logos/logo--en.svg');
    }

    .europeanUnionWhiteLogo {
      background-repeat: no-repeat;
      background-image: url('/views/_assets/images/logos/logo--white--en.svg');
    }
    @media (max-width: 768px) {
      .europeanUnionBlackLogo {
        background-repeat: no-repeat;
        background-image: url('/views/_assets/images/logos/eu_condensed.svg');
      }

      .europeanUnionWhiteLogo {
        background-repeat: no-repeat;
        background-image: url('/views/_assets/images/logos/logo--white--en.svg');
      }
    }
  }

  .searchBarWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .language {
      font-weight: bold;
      color: var(--c-blue-120);
      &::after {
        background-image: url('/views/_assets/images/logos/englishIcon.jpg');
        content: '\00a0' !important;
        display: inline-block;
        width: 25px;
        height: 23px;
        margin-left: 0.5rem;
      }
    }
    .searchBar {
      width: 427px;
      height: 46.7px;
      margin-left: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      :global .p-inputtext {
        height: 46.7px;
        border-radius: 0px;
        &:focus {
          height: 43.7px;
          border-color: #fbc11d;
          outline: 3px solid #fbc11d;
          outline-offset: 0;
          text-decoration: none;
        }
      }
      button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: none;
        border: 2px solid transparent;
        box-sizing: border-box;
        display: inline-block;
        font-family: Arial, sans-serif;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1;
        margin: 0;
        padding: 0.89rem 1rem;
        text-decoration: none;
        background-color: #e3e3e3;
        border-color: #e3e3e3;
        color: #004494;
        cursor: pointer;
        &:focus {
          background-color: #004494;
          color: #fff;
          outline: 3px solid #ffd617;
          outline-offset: -3px;
        }
      }
    }
  }
}
