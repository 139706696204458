.container {
  width: 100%;
  display: flex;
  align-items: center;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    list-style: none;
    margin: 0px;
    padding-left: 1.6rem;
    li {
      margin-bottom: 0px;
      span {
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
          color: var(--breadCrumb-icon-color);
        }
        span {
          color: var(--breadCrumb-font-color);
        }
      }
      color: var(--breadCrumb-chevron-color);
    }
    li:last-child {
      font-weight: bold;
    }
  }
  &.isPublic {
    ul {
      li {
        span {
          color: white;
        }
      }
    }
  }
}
.p-breadcrumb-chevron,
.p-breadcrumb-home {
  vertical-align: middle;
  color: var(--c-corporate-yellow) !important;
}
