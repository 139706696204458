.sectionMainContent {
  border: 1px solid var(--general-border-color);
  border-radius: 3px;
  width: 100%;
  margin: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;
}

.settingsContainer {
  border-radius: 5px !important;
  border: 1px solid var(--card-box-shadow);
}

.userConfiguration {
  width: 70%;
}

.userCard {
  width: 30%;
}
