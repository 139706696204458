.buttonWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.cancelButton {
  margin-left: 0.5rem !important;
}
.search {
  display: flex;
  flex-direction: row;
}
.searchInput {
  border-right: none;
  padding: 5px;
  outline: none;
  border-radius: 3px 0 0 3px;
}
.hasErrors {
  border-color: var(--errors);
  box-shadow: inset 0px 0px 0px 1px var(--errors);
  outline: none;
}
