.datasetSchemaPropertiesWrapper {
  margin-bottom: 1rem;
  .header {
    color: var(--subtitle-color);
  }
}

.datasetSchemaToolbar {
  border: 0px !important;
  margin-bottom: 1rem;
}

.levelError {
  border-radius: 3px;
  color: white;
  font-size: 10pt;
  font-weight: 600;
  padding: 4px;
  margin-top: 2px;
}

.blocker {
  background-color: rgba(var(--statusBox-blocker-bg), 0.8) !important;
  color: rgba(var(--statusBox-blocker-color), 0.8) !important;
}

.error {
  background-color: var(--statusBox-error-bg) !important;
  color: var(--statusBox-error-color) !important;
}

.info {
  background-color: var(--statusBox-info-bg) !important;
  color: var(--statusBox-info-color) !important;
}

.externalIntegrationsTab {
  transform: rotate(-90deg);
}

.noTables {
  margin: 1rem 0;
  display: block;
  font-weight: bold;
  font-style: italic;
}

.warning {
  background-color: var(--statusBox-warning-bg) !important;
  color: var(--statusBox-warning-color) !important;
}

.property {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  .propertyTitle {
    font-weight: bold;
  }
  .propertyValue {
    margin-left: 0.5rem;
  }
}
