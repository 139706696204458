@import '_styles/mixins';

.expression {
  display: flex;
  justify-content: flex-start;
  --unionWidth: 85px;
  --operatorsWidth: 150px;
  --fieldsGap: 1rem;
  .group {
    margin-right: 1rem;
    :global .p-checkbox {
      width: 33px !important;
      height: 33px !important;
    }
    :global .p-checkbox-box {
      width: 33px !important;
      height: 33px !important;
      :global .p-checkbox-icon {
        font-size: 27px;
      }
    }
  }
  .union {
    width: var(--unionWidth);
    :global .p-dropdown {
      width: 100%;
    }
  }
  .operatorType {
    width: var(--operatorsWidth);
    :global .p-dropdown {
      width: 100%;
    }
  }
  .operatorValue {
    width: var(--operatorsWidth);
    :global .p-dropdown {
      width: 100%;
    }
  }
  .expressionValue {
    height: 0.5px;
    width: calc(100% - (var(--operatorsWidth) * 2) - var(--unionWidth) - (var(--fieldsGap) * 5));
    :global .p-inputnumber {
      width: 100%;
    }
  }
  > span {
    margin-right: var(--fieldsGap);
    &:last-of-type {
      margin-right: 0;
    }
  }
  .deleteButton {
    border: 1px solid var(--general-border-color) !important;
    @include shadow-0em;
    color: var(--errors) !important;
    margin: 0 !important;
  }
}
.inputStringMatch {
  display: flex;
  align-items: flex-start;
  .ccButton {
    margin: 0 !important;
    margin-left: 0.4rem !important;
    border: 1px solid var(--general-border-color) !important;
    padding: 0.37rem 0.45rem;
  }
}
