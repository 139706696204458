@import '_styles/mixins';

.boundaryWrap {
  margin-top: 4rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  h3 {
    color: var(--main-font-color);
    max-width: 88%;
  }
  .boundaryContainer {
    background-color: var(--error-boundary-bg);
    position: relative;
    max-width: 50vw;
    box-shadow: 0 5px 0.5rem rgba(0, 0, 0, 0.6);
    padding: 2rem;
    h2 {
      max-width: 75%;
      margin: 0;
      font-size: 3rem;
      color: var(--accent-color);
      line-height: 3.5rem;
      @include for-phone-only {
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    }
    svg {
      position: absolute;
      top: 2rem;
      right: 2rem;
      font-size: 8rem;
      color: var(--warning-color);
      opacity: 1;
      @include for-phone-only {
        font-size: 3rem;
      }
      @include for-phone-landscape-up {
        font-size: 4.5rem;
      }
      @include for-tablet-landscape-up {
        font-size: 8rem;
      }
    }
    .errorTexts {
      margin-top: 2rem;
      color: var(--main-font-color);
      p {
        margin: 0.5rem 0;
      }
    }
    .boundaryButtonsWrap {
      margin-top: 4rem;
      display: flex;
      justify-content: space-between;
      @include for-phone-only {
        flex-direction: column;
        align-items: center;
        > div {
          margin-bottom: 1rem;
        }
        @include for-phone-landscape-up {
          flex-direction: row;
          margin-top: 2rem;
          > div {
            margin-bottom: 0;
          }
        }
      }
      @include for-tablet-portrait-up {
        margin-top: 2rem;
      }
      @include for-big-desktop-up {
        margin-top: 4rem;
      }
    }
    @include for-phone-only {
      max-width: 100%;
    }
    @include for-tablet-portrait-up {
      max-width: 80%;
    }
    @include for-desktop-up {
      max-width: 50vw;
    }
  }
  @include for-tablet-portrait-up {
    margin-top: 2rem;
  }
  @include for-big-desktop-up {
    margin-top: 4rem;
  }
}
