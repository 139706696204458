.card {
  background: var(--card-item-bg);
  border-radius: 15px;
  box-shadow: var(--card-item-box-shadow);
  column-gap: 1.5rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 85% calc(15% - 1.5rem);
  grid-template-rows: 70% calc(30% - 1rem);
  height: 150px;
  padding: 1rem;
  position: relative;
  row-gap: 1rem;
  transition: all 0.1s ease-in-out;

  &:hover {
    box-shadow: var(--card-item-box-shadow-hover);
    transform: scale(1.02, 1.02);
    transition: transform 0.1s ease;
    background: var(--card-checked-bg);
  }

  &::before {
    content: '';
    background-image: url('/views/_assets/images/logos/logo.png') !important;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 150% 50%;
    bottom: 0;
    left: 0;
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      display: -webkit-box;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .subtitle {
      display: -webkit-box;
      margin: 0.5rem 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    .linkIcon {
      cursor: pointer;
    }
  }

  .date {
    align-items: center;
    display: flex;
    grid-column-start: span 2;

    &.cloneSchemas {
      justify-content: space-between;
    }

    .dueDate {
      font-weight: bold;
      padding: 0.3rem;
    }
  }

  .toolbar {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &.DRAFT {
    background: var(--c-lilac-300);
    color: var(--c-white-color);
    opacity: 0.8;

    &::before {
      background-image: url('/views/_assets/images/logos/logo-blanco.png') !important;
      background-position: 150% 50%;
      background-repeat: no-repeat;
      background-size: 50%;
      border-radius: 15px;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}

.checked {
  position: relative;
  z-index: 0;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: var(--card-checked-bg);
    border-radius: 15px;
    opacity: 1;

    background-image: url('/views/_assets/images/logos/logo.png');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: 150% 50%;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -5;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    opacity: 0.8;
    background-color: var(--accent-color);
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }

  &.DRAFT {
    color: var(--c-white-color);
    opacity: 1;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 2px;
      top: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background: var(--c-lilac-300);
      border-radius: 15px;
      opacity: 1;

      background-image: url('/views/_assets/images/logos/logo.png');
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: 150% 50%;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: -5;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      opacity: 0.8;
      background-color: var(--c-white-color);
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    }
  }
}
