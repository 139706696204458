.block {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
  width: 100%;

  .dateBlock {
    display: flex;
    align-items: flex-end;
    position: relative;
    border-radius: 0px !important;
    border: 0px !important;
    color: var(--font-filters) !important;
    height: 3em;
    padding-left: 0em;
    width: 100%;

    input {
      background: transparent;
      border: 0;
      border-radius: 0;

      &:focus,
      &:enabled:focus {
        box-shadow: 0 0 0 0 var(--c-blue-120) !important;
      }
    }

    span {
      width: 100%;
    }
  }
  .elementFilter {
    border-bottom: var(--inputtext-border) !important;
    label {
      position: absolute;
      font-size: 0.8333rem !important;
      top: 2.2em !important;
      color: var(--font-filters);
    }
  }

  .elementFilterSelected {
    border-bottom: var(--active-filter-border) !important;
    box-shadow: var(--active-filter-shadow);
    button {
      background-color: transparent !important;
      span {
        color: var(--active-filter);
      }
    }
    label {
      position: absolute;
      font-size: 0.8333rem !important;
      top: 0.5em !important;
      color: var(--font-filters);
    }
  }
}

@media (min-width: 600px) {
  .block {
    width: 50% !important;
  }
}
