.dropDownMenu {
  background-color: var(--dropdown-menu-bg-color) !important;
  border-radius: 2%;
  border: 1px solid var(--c-custom-gray);
  bottom: 0px;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  color: var(--main-font-color);
  cursor: pointer;
  font-size: 0.9rem;
  height: fit-content;
  opacity: 0;
  position: absolute;
  user-select: none;
  z-index: 3003;

  ul {
    list-style: none;
    padding: 0px;

    li {
      span {
        color: var(--main-font-color);
        padding: 0.85em 0.45em !important;
      }
      svg {
        color: var(--main-font-color);
      }
      &:hover {
        background-color: var(--dropdown-menu-items-bg-color-hover);
      }
    }
  }
}
