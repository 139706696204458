.list {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: var(--main-font-color);
    background-color: var(--bg);
    margin: 0;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    cursor: pointer;
    user-select: none;
  }
}

.labelItem {
  margin: 0rem;
  padding-left: 1rem;
  cursor: pointer;
}

.checkbox:not(:checked) + .labelItem {
  text-decoration-line: line-through;
  opacity: 0.5;
}

.checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  appearance: none;
  border: transparent;
  display: flex;
  flex-direction: row;
  width: 2.5rem;
  height: 0.8rem;
  padding: 0;
  border-radius: 0px;

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
  &[data-status='correct'],
  &[data-status='info'],
  &[data-status='warning'],
  &[data-status='error'],
  &[data-status='blocker'] {
    border: 1px solid var(--c-gray-50);
  }

  &:not(:checked) {
    opacity: 0.5;
  }
}
