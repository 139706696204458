@import '_styles/mixins';

.infoTablePaste {
  border-style: dashed;
  border-color: var(--c-gray-75);
  opacity: 0.5;
  height: 34vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.infoTableItem {
  flex-grow: 1;
  text-align: center;
}
.lineBreak {
  flex-basis: 100%;
  height: 0;
}

.infoTableData tr td {
  padding: 0px !important;
}

.infoTableCellCorrect {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.429em 0.857em;
}

.deleteRowButton {
  border: 1px solid var(--general-border-color) !important;
  color: var(--errors) !important;
  @include shadow-0em;
  &:hover:enabled {
    border-color: var(--button-secondary-border-color-hover) !important;
  }
}

.infoTableCellError {
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 10px,
    rgba(218, 33, 49, 0.3) 10px,
    rgba(218, 33, 49, 0.3) 15px
  ) !important;
  width: 100%;
  height: 100%;
}

.infoTableSpinner {
  top: 0vh !important;
}

.fieldText {
  text-align: left;
  font-weight: bold;
}

.fieldTextPaddingTop {
  padding-top: 1rem;
}

.propertyLabel {
  color: var(--success-color-lighter);
  font-weight: 600;
}

.propertyLabelListItems {
  width: 16rem;
  display: block;
}

.iconSize {
  font-size: 8pt;
}
