.statusBox {
  border-radius: 3px;
  color: var(--main-font-color);
  font-weight: 600;
  padding: 4px;
}

.blocker,
.refused {
  background-color: rgba(var(--statusBox-blocker-bg), 0.8);
  color: rgba(var(--statusBox-blocker-color), 0.8);
}

.closed {
  background-color: var(--c-gray-75);
  color: var(--c-white-color);
}

.design {
  background-color: var(--statusBox-designer-bg);
  color: var(--c-black-color);
}

.canceled_by_admin {
  background-color: rgb(253, 204, 113);
  color: rgb(185, 67, 24);
}

.disabled,
.failed {
  background-color: var(--statusBox-false-bg);
  color: var(--statusBox-false-color);
}

.open,
.draft {
  background-color: var(--statusBox-draft-bg);
  color: var(--statusBox-draft-color);
}

.error,
.incorrect,
.invalid,
.canceled {
  background-color: var(--statusBox-error-bg);
  color: var(--statusBox-error-color);
}

.info,
.inQueue,
.queued {
  background-color: var(--statusBox-info-bg);
  color: var(--statusBox-info-color);
}

.success,
.finished {
  background-color: var(--c-growl-success-lighter-green);
  color: var(--c-growl-success-darker-green);
}

.valid,
.enabled,
.correct {
  background-color: var(--statusBox-true-bg);
  color: var(--statusBox-true-color);
}

.warning,
.warn,
.inProgress {
  background-color: var(--statusBox-warning-bg);
  color: var(--statusBox-warning-color);
}
