.content {
  background: var(--webform-table-bg);
  border: var(--webform-table-border);
  padding: 2rem 3.1rem 2rem 2rem;
  position: relative;
  color: var(--general-text-color);
  border-radius: 3px;
  margin: 10px 0 0 0;

  .actionButtons {
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;

    .collapse,
    .delete {
      border-radius: 5px;
      border: 1px solid var(--c-custom-gray);
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .delete {
      color: var(--errors);
    }
  }
  .attachmentWrapper {
    button {
      margin-right: 0.5rem;
    }
  }
  .label2 {
    font-size: 1.75rem;
    margin: 2.5rem 0 1.25rem 0;
    display: inline-block;
  }
  .label3 {
    font-size: 1.25rem;
    margin: 0 0 1rem;
    display: inline-block;
  }
  .label4 {
    font-weight: 400;
    display: inline-block;
  }
  .field + h3 {
    margin-top: 3rem;
  }
  h3 {
    .infoButtonWrapper {
      border-radius: 5px;
      border: 0.1rem solid var(--c-gray-25);
      padding: 1rem;
      display: flex;
      align-items: center;
      .infoButton {
        background-color: var(--c-white-color) !important;
        border-radius: 50% !important;
        color: red !important;
        font-size: 12pt !important;
        height: 0.7em !important;
        margin-left: 0.4rem !important;
        width: 0.7em !important;
        margin-right: 0.25rem;

        > span {
          left: 46% !important;
          margin-left: -0.48em !important;
          margin-top: -0.5em !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }
  }

  h3 + .subTable {
    margin-top: 0;
  }

  h2 + .field {
    margin-top: 1rem;
  }

  .fieldsBlock {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    .field {
      flex: auto;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      .template {
        color: var(--general-text-color);
        width: 100%;
      }
      label {
        max-width: 100%;
      }
    }
  }

  .field {
    margin-bottom: 2rem;
    color: var(--general-text-color);
    :global .p-multiselect {
      width: 100%;
      padding: 0.2rem 0;
    }
    :global .p-dropdown {
      width: 100%;
    }
    :global .p-inputtext:not(.p-column-filter) {
      padding: 1rem 0.5rem;
    }
    label {
      display: inline-block;
      max-width: 75%;
      margin-bottom: 0.5rem;
    }
    .requiredMark {
      color: var(--errors);
    }
    .label2 {
      margin-top: 4rem 0 0 0;
    }
    .label3 {
      margin: 2px 0 0 0;
    }

    .content {
      display: flex;
    }

    .template {
      width: 90%;

      .nonExistField {
        color: var(--errors);
      }
    }

    :global .webform-validationErrors {
      width: 2em !important;
      &:first-of-type {
        margin-left: 1rem !important;
      }
      :global .pi {
        font-size: 1.8em;
      }
    }

    .infoButtonWrapper {
      border-radius: 5px;
      border: 0.1rem solid var(--c-gray-25);
      padding: 1rem;
      display: flex;
      align-items: center;
      .infoButton {
        background-color: var(--c-white-color) !important;
        border-radius: 50% !important;
        color: var(--errors) !important;
        font-size: 12pt !important;
        height: 0.7em !important;
        margin-left: 0.4rem !important;
        width: 0.7em !important;
        margin-right: 0.25rem;

        > span {
          left: 46% !important;
          margin-left: -0.48em !important;
          margin-top: -0.5em !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .infoCircle {
      background-color: var(--c-white-color);
      border-radius: 50%;
      color: var(--datatable-header-info-button-color);
      font-size: 12pt;
      height: 0.7em;
      margin-left: 0.4rem;
      width: 0.7em;

      > span {
        left: 46%;
        margin-left: -0.48em;
        margin-top: -0.5em;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
  .infoCircle {
    background-color: var(--c-white-color) !important;
    border-radius: 50% !important;
    color: var(--datatable-header-info-button-color) !important;
    font-size: 12pt;
    height: 0.7em;
    margin-left: 0.4rem !important;
    width: 0.7em !important;

    > span {
      left: 46%;
      margin-left: -0.48em;
      margin-top: -0.5em;
    }

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }

  .errorList {
    list-style: none;
    padding: 0;
    margin: 0;

    .errorItem {
      padding: 0.5rem;
    }
  }
}

.subTable {
  padding: 1rem;
  margin: 2rem 0 2rem 0;
  border: 1px solid var(--c-gray-25);
  color: var(--general-text-color);
  .content {
    border: 1px solid var(--c-gray-25);
  }
  .title {
    border-radius: 3px;
    border: var(--general-border-style);
    padding: 0 1rem;
    margin: 0.5rem 0;
  }
}

.showInsideParentTable {
  .content {
    border: none;
    padding: 0;
  }
}
.showInsideParentTable {
  .title {
    h3 {
      padding: 0 0 1em 0;
    }
  }
}

.subTable,
.showInsideParentTable {
  border-radius: 3px;
  height: 100%;
  width: 100%;
  border-color: var(--general-border-color);
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-weight: 400;
    }
  }

  .nonExistTable {
    color: var(--errors);
  }

  .content {
    margin-bottom: 0.5rem;
    padding-bottom: 0;
  }
}

.fieldWrapper {
  display: flex;
}
