.p-listbox {
  padding: 0.25em;
  width: 10em;
}

.p-listbox .p-listbox-list-wrapper {
  max-height: 50vh;
  overflow-y: scroll;
  scrollbar-color: var(--bg) var(--c-custom-gray);
  scrollbar-width: thin;
}

.p-listbox .p-listbox-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.p-listbox .p-listbox-item {
  padding: 0.25em;
  border: 0 none;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 1px;
}

.p-listbox .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: var(--listbox-item-highlight-bg-hover);
}

.p-listbox .p-listbox-item > span {
  vertical-align: middle;
}

.p-listbox .p-listbox-item:last-child {
  margin-bottom: 0;
}

.p-listbox.p-disabled .p-listbox-item {
  cursor: default;
}

.p-listbox .p-highlight {
  background-color: var(--listbox-item-highlight-bg);
  color: var(--listbox-item-highlight-color);
}

.p-disabled {
  opacity: 0.6;
  outline: none;
}

.p-listbox-header {
  margin-bottom: 0.3em;
  padding: 0.125em 0.2em;
  position: relative;
}

.p-listbox-header .p-checkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.p-listbox-header .p-listbox-filter-container {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 100%;
}

.p-listbox-header.p-listbox-header-w-checkbox .p-listbox-filter-container {
  width: calc(100% - 2em);
}

.p-listbox-header .p-listbox-filter-container .p-listbox-filter-icon {
  position: absolute;
  top: 0.25em;
  left: 0.25em;
}

.p-listbox-header .p-inputtext {
  padding: 0.125em 0.125em 0.125em 1.25em;
  width: 100%;
}

.p-listbox-title-wrapper {
  height: auto;
  min-height: 2rem;
  border-bottom: 1px solid var(--main-font-color);
  overflow: scroll;
}
.p-listbox-title-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}
.p-listbox-title-wrapper::-webkit-scrollbar-track {
  background: var(--bg);
}

.p-listbox-title-wrapper::-webkit-scrollbar-thumb {
  background: var(--c-gray-75);
}

.p-listbox-title-wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--c-gray-50);
}

.p-listbox-title {
  font-size: 0.9em;
  font-weight: bold;
  text-overflow: ellipsis;
}

.p-listbox-list-wrapper::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.p-listbox-list-wrapper::-webkit-scrollbar-track {
  background: var(--bg);
}

.p-listbox-list-wrapper::-webkit-scrollbar-thumb {
  background: var(--c-gray-75);
}

.p-listbox-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: var(--c-gray-50);
}
.listbox-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
