.leftSideBarElementWrapper {
  display: flex;
  align-items: center;
  width: 40px;
}
.leftSideBarUserIcon {
  margin-right: 12px;
}

.leftSideBarElementAnimation {
  transition: all 0.08s ease;
}

.leftSideBarElementAnimation:hover {
  transform: scale(1.25);
  transition: 0.08s ease-in;
}

.leftSideBarElementSystemNotification {
  transition: 0.6s ease-in;
  animation: blink 0.6s;
}

.leftSideBarElementNotification {
  transition: 0.3s ease-in;
  animation: bell 0.6s;
}

.leftSideBarLogo:hover {
  transition: 0.3s ease-in;
  animation: blink 0.3s;
}
.leftSideBarUserText {
  font-size: 14px;
}
.notificationCounter {
  position: absolute;
  top: -3px;
  background-color: var(--errors);
  color: var(--c-white-color);
  border-radius: 3px;
  padding: 1px 3px;
  font: 8px Verdana;
}
.notificationIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.systemNotificationMark {
  position: absolute;
  top: -8px;
  right: 16px;
  background-color: var(--errors);
  color: var(--c-white-color);
  border-radius: 3px;
  padding: 1px 3px;
  font: 8pt Verdana;
  font-weight: bold;
}

.tooltipClass {
  border: 1px solid var(--main-font-color) !important;
  margin-left: 0 !important;
  &:after {
    border-right-color: var(--main-font-color) !important;
  }
}

@keyframes blink {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bell {
  0% {
    transform: rotate(35deg);
  }
  12.5% {
    transform: rotate(-30deg);
  }
  25% {
    transform: rotate(25deg);
  }
  37.5% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(15deg);
  }
  62.5% {
    transform: rotate(-10deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
}
