.table {
  .spinner {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    overflow: hidden;
    position: absolute;
    user-select: none;
    width: 100%;

    svg {
      width: 100px;
    }
  }
}
.disabledInput {
  opacity: 0.8;
}

.emptyTableHeader {
  :global .p-column-title {
    display: none;
  }
}

.accountWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.isValidUserIcon {
  cursor: pointer;
  color: var(--isValid-user-icon-color);
  font-size: 1.1rem;
}

.manageDialog {
  display: flex;
  flex-direction: column;
  div {
    padding: 0 0.5rem;
    width: 100%;

    .label {
      font-size: 1rem;
    }
  }
  .inputWrapper {
    margin-bottom: 1rem;
  }
}
.table tbody > tr {
  height: 3rem !important;
}
.error {
  border-color: var(--errors) !important;
  box-shadow: inset 0px 0px 0px 1px var(--errors) !important;
  outline: none;
  overflow: hidden;
}
.shareRightsModal {
  min-height: 50vh;
}
.shareRightsDisclaimer {
  font-style: italic;
  font-weight: bold;
}

.shareRightsModal {
  $root: &;
  @at-root {
    .wrapperNoUserRoles {
      @at-root {
        #{$root} #{&} {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.wrapperNoUserRoles,
.wrapperEmptyFilter {
  height: 35vh;
  display: flex;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: bold;
}
