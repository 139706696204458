.content {
  margin-top: 6px;
  .repContainer {
    h1 {
      margin-top: 2rem;
      font-size: 2.25rem;
    }

    .noDataflows {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.4rem;
      font-weight: bold;
      height: 30vh;
      width: 100%;
    }

    > div:nth-child(2) > div:nth-child(n + 1):nth-child(-n + 7) {
      width: 25% !important;
    }

    > div > div:nth-child(8):not([class*='responsiveCardsGrid']) {
      margin-left: auto;
      display: flex;
      flex-wrap: wrap;
      width: auto !important;
    }
  }
}

.obligationTitle{
  margin-top:2rem;
  margin-bottom:2rem;
}

.bottomPaginator {
  margin: 3rem 0;
}

.topPaginator {
  margin-bottom: 1rem;
}

.currentPageWrapper {
  color: var(--c-white-color);
  margin-left: 1.5rem;
  user-select: none;
  .currentPageInput {
    display: inline;
    height: 1.75rem;
    width: 2.5rem;
  }
  .currentPageLabel {
    margin: 0 0.5rem;
  }
  .currentPageOf {
    margin: 0 0 0 0.5rem;
  }
}

.spinner {
  left: 0 !important;
  top: 0 !important;
}

.paginator {
  border-radius: 10px;
}
