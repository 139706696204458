.integrations {
  height: 60vh;
}

.integrationsWithoutTable {
  height: 63.5vh;
}

.emptyFilteredData,
.noIntegrations {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.emptyFilteredData {
  height: 75%;
}

.noIntegrations {
  height: 100%;
}

.spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinnerPosition {
    top: 0;
    left: 0;
  }
}
