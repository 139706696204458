.dropDownMenu {
  position: absolute;
  bottom: 0px;
  right: -80px;
  cursor: pointer;
  font-size: 0.8rem;
  background-color: var(--dropdown-menu-bg-color);
  width: 200px;
  border-radius: 2%;
  z-index: 1000;
  padding: 0 0.8rem;
  color: var(--main-font-color);
  border: 1px solid var(--c-custom-gray);
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 0.9rem;
  opacity: 0;
  user-select: none;
  padding: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    .listItemEnabled {
      &:hover {
        background-color: var(--dropdown-menu-items-bg-color-hover);
      }
      div {
        display: flex;
        align-items: center;
        em {
          margin-right: 0.5rem;
          font-size: 1.5em;
        }
      }
    }
    li {
      margin: 0;
      padding: 0.7rem 1rem 0.7rem 1rem;
    }
  }
}

.menuItemDisabled {
  color: var(--c-black-300) !important;
}
.menuItemDisabled:hover {
  color: var(--c-black-300);
}

.listItemTitle {
  cursor: default;
  background: var(--list-item-title-bg);
  font-weight: bold;
  font-size: 0.9em;
}
