.datasetSchemasToolbar {
  border: 0px !important;
}

.index {
  color: var(--subtitle-color);
}

.positioning {
  top: 0 !important;
}
