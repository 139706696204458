.header {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: var(--header-height);
  overflow: hidden;
  background-color: var(--header-footer-bg);
  transition: 0.5s;
  box-shadow: var(--header-box-shadow);
  z-index: 3002;
  &.public {
    background-color: var(--public-page-header-bg);
  }

  .customHeader {
    display: flex;
    padding: 0.5rem 1rem;
    justify-content: space-between;
    &.public {
      background-color: var(--public-custom-header-bg);
      @media (max-width: 768px) {
        justify-content: space-between;
      }
    }
    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      img {
        height: 3rem;
        margin-right: 1rem;
      }
      h1 {
        margin: 0px;
        font-size: 1.5rem;
        color: var(--main-font-color);
      }
    }
    .localhostAlert {
      display: flex;
      align-items: center;
      color: var(--main-font-color);
    }
    .loginWrapper {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      .loginButton {
        background-color: var(--c-white-color);
        color: var(--public-custom-header-bg);
        padding: 0.25rem 0.9rem;
        font-weight: bold;
        span:first-child {
          margin-left: 0.5rem;
          margin-top: -0.5rem;
        }
      }
    }

    .userWrapper {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      a {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        span {
          font-weight: 500;
          font-size: 0.8rem;
          color: var(--main-font-color);
        }
        svg {
          color: var(--main-font-color);
        }
      }
      .userAvatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 1rem;
        margin-left: 1rem;
        font-size: 0.78rem !important;
      }
    }
    .logoutWrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        color: var(--main-font-color);
      }
      .logoutButton {
        transition: all 0.08s ease;
        margin-right: 1rem;
      }

      .logoutButton:hover {
        transform: scale(1.25);
        transition: 0.08s ease-in;
      }
    }
    .themeSwitcherTooltip {
      z-index: 3004;
    }

    .themeSwitcherInputSwitch:before {
      background-color: var(--c-black-color);
      box-shadow: var(--inputswitch-dark-theme-checked);
    }
  }
  @media (max-width: 768px) {
  }
}

.logoutBtnContainer {
  display: flex;
  align-items: center;
}
