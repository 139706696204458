.errorAndCounterWrapper {
  height: 40px;
}

.search {
  --searchButtonWidth: 170px;
  cursor: pointer !important;
  display: flex;
  justify-content: space-between;

  .searchInput {
    background-color: var(--form-field-bg);
    border-radius: 0 !important;
    border: none;
    color: var(--main-font-color);
    cursor: default !important;
    outline: none;
    padding: 10px;
    text-overflow: ellipsis;
    width: calc(100% - var(--searchButtonWidth));

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .searchErrors {
    &::placeholder {
      color: var(--errors);
      opacity: 1;
    }
  }
}

.deliveryDate {
  --searchButtonWidth: 170px;
  cursor: pointer !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem !important;
}

.fieldset {
  padding: 0;
}

.dataflowName {
  &:hover {
    border-color: var(--inputtext-border-color-focus) !important;
    box-shadow: none !important;
    &:focus {
      box-shadow: var(--inputtext-box-shadow-focus) !important;
    }
  }
}

.inputTextArea {
  height: auto !important;
  margin-top: 1rem !important;
}

.dropdownsWrapper {
  display: flex;
  margin: 1rem 0;
}

.deliveryDateText {
  background-color: var(--form-field-bg);
  border-radius: 0 !important;
  border: none;
  color: var(--main-font-color);
  cursor: default !important;
  outline: none;
  padding: 10px;
  text-overflow: ellipsis;
  width: calc(100% - var(--searchButtonWidth));
}
