.container {
  width: 334px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 6000;
  box-shadow: var(--calendar-box-shadow);
  animation: slideDown 0.3s;
  transition: all 300ms ease;
  .buttonWrapper {
    margin-top: 0.5rem;
    button {
      width: 100%;
    }
  }

  .labelDate,
  .labelText {
    color: var(--main-font-color);
    margin-left: 0.2rem;
    font-size: smaller;
  }
  .labelDate {
    font-weight: 600;
  }

  .footer {
    background-color: var(--bg);

    padding: 0.25rem 0.5rem 0.75rem 0.5rem;
    border: 1px solid var(--c-gray-50);
    border-radius: 3px;
  }

  .inputMaskWrapper {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    .timeInput {
      width: 6rem !important;
    }

    .error {
      border: var(--inputtext-border-error);

      &:focus {
        border: var(--inputtext-border-error);
        box-shadow: var(--inputtext-box-shadow-focus-error);
      }
      &:hover {
        border: var(--inputtext-border-error);
      }
    }

    .utc {
      padding-left: 0.5rem;
      justify-content: flex-end;
      display: flex;

      .label {
        align-self: center;
        color: var(--main-font-color);
      }

      .dropdown {
        margin-left: 0.5rem;
        min-width: 8rem;
        justify-self: flex-end;
      }
    }
  }
}
.hiddenDiv {
  min-height: 33px;
}

@keyframes slideDown {
  from {
    transform: translateY(5%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
