.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: var(--c-white-color);
  text-align: center;
  padding: 15px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 2000;
  bottom: 0;

  margin-left: -0;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

td > .tooltip > span.tooltiptext {
  overflow: visible !important;
}

.buttonCustom {
  padding: 0px !important;
  margin: 0px !important;
  border-color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
  justify-self: flex-end !important;
}

.buttonCustom.info {
  color: var(--c-growl-info-darker-blue);
}
.buttonCustom.info:hover {
  color: var(--c-growl-info-darker-blue) !important;
}
.buttonCustom.warning {
  color: var(--c-growl-warning-darker-yellow);
}
.buttonCustom.warning:hover {
  color: var(--c-growl-warning-darker-yellow) !important;
}
.buttonCustom.error {
  color: var(--errors);
}
.buttonCustom.error:hover {
  color: var(--errors) !important;
}
.buttonCustom.blocker {
  color: var(--blockers);
}
.buttonCustom.blocker:hover {
  color: var(--blockers) !important;
}

:global .p-tooltip {
  max-width: 30rem !important;
}
:global .p-tooltip-text {
  padding: 1rem !important;
  word-wrap: break-word !important;
}
