.validExtensionsChips {
  margin-top: 5px;
  width: 100%;
}
.validExtensionsChips li span {
  white-space: normal;
  word-wrap: break-word;
}
.inputTitleWrapper {
  margin-bottom: 1rem;
}
.maxSizeInput {
  height: 36px;
  width: 10%;
}
.maxSizeWrapper {
  display: flex;
  margin-top: 0.5rem;
}
.maxSizeWrapper span {
  margin-top: inherit;
}
.mbSpan {
  font-weight: bold;
  margin-left: 0.5rem;
}
.pasteDiv {
  padding: 1rem;
  border: 2px dashed var(--c-gray-25);
}
.validExtensionsWrapper {
  margin-bottom: 1rem;
}
