@import '_styles/mixins';

.draggableFieldDiv .inputField {
  margin-left: 2.5rem;
  width: 20%;
  &.withCodeListOrLink {
    width: 15%;
  }
}

.draggableFieldDivDisabled {
  pointer-events: none !important;
  text-decoration: none;
  opacity: 0.5;
  cursor: not-allowed;
}
.inputFieldDescription {
  margin-left: 0.4rem !important;
  width: 15%;
  &.withCodeListOrLink {
    width: 15%;
  }
  @include for-big-desktop-up {
    width: 25%;
  }
}

.emptyCodelistOrLink {
  display: none;
}

.dropDownLabel {
  div {
    display: grid !important;
    label {
      display: flex !important;
      justify-content: space-between;
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.draggableFieldDiv {
  .bigItems {
    &:last-child {
      .draggableFieldCell {
        &:last-child {
          label > span {
            overflow: unset;
          }
        }
      }
    }
  }
}

.draggableFieldDiv {
  display: table;
  width: 100%;
  margin: 5% auto 0;
  text-align: center;
  background-color: var(--bg-table);
  border: 1px solid var(--box-shadow-table);
  border-radius: 4px;
  box-shadow: 0px 4px var(--box-shadow-table);
  .draggableFieldContentCell {
    height: 3em;
    display: flex;
    justify-content: center;
    .draggableFieldCell {
      display: flex;
      flex-direction: row;
      width: 50%;
      border-bottom: 1px solid var(--box-shadow-table);
      &:first-child {
        display: flex;
        text-align: left;
        width: 50%;
        font-weight: bold;
        padding: 0 1em;
        align-items: center;
      }
      &:last-child {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1em;
        div > svg {
          bottom: 0.7rem;
        }
        div > label > svg {
          display: none;
        }
      }
      button {
        background-color: var(--bg-table-button);
      }

      input,
      .dropdownFieldType,
      .button {
        max-height: 33px !important;
      }
    }
  }
}

.draggableFieldDiv:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.disablePointerEvent * {
  pointer-events: none;
}

.dragAndDropActive {
  pointer-events: none;
}

.dragAndDropInactive {
  pointer-events: auto;
}

.dropdownFieldType {
  width: 100%;
  text-align: left;
  &.withCodeListOrLink {
    width: 100%;
  }
}

.codelistButton {
  border: 1px solid var(--general-border-color) !important;
  padding: 0 0.5em !important;
  height: 1.95rem;
  width: 100%;
  &:hover {
    border-color: var(--button-secondary-border-color-hover) !important;
  }
}

.codelistButton span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 8em;
  font-size: 13px;
  text-align: left;
}

.button {
  width: 2.5em;
  padding-top: 0.4rem;
  padding-bottom: 0.35rem;
  border-radius: 3px;
  border: 1px solid var(--general-border-color);
  color: var(--main-font-color);
  background-color: var(--bg-table-button);
  &:hover {
    background-color: var(--button-secondary-transparent-bg-hover);
    color: var(--main-font-color);
    border-color: var(--button-secondary-border-color-hover);
  }
}

.deleteButton {
  color: var(--errors);
  cursor: pointer;
  &:hover {
    color: var(--errors);
    border-color: var(--button-secondary-border-color-hover);
  }
}

.disabledButton {
  pointer-events: none !important;
  text-decoration: none;
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: transparent;
  }
}

.dragAndDropIcon {
  width: 32px;
}

.duplicateButton {
  cursor: pointer;
  &:hover {
    background-color: var(--button-secondary-transparent-bg-hover);
    border-color: var(--button-secondary-border-color-hover);
  }
}

.fieldSeparator:first-child {
  order: 1;
}

.fieldSeparatorDragging {
  &:before {
    content: '';
    pointer-events: none;
    animation-duration: 100ms;
    animation-iteration-count: 1;
    animation-name: fadeInBorder;
    animation-timing-function: ease-in;
    background-color: var(--field-designer-separator-bg);
    border-radius: 3px;
    border: 2px dashed var(--field-designer-separator-border-color) !important;
    box-shadow: var(--field-designer-separator-box-shadow) !important;
    display: block;
    grid-area: 1 / 1 / 2 / 11;
    height: 30px;
    margin: 5px 0;
    opacity: 0.7;
    padding: 5px;
    width: 98%;
  }
}

.fadeIn {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 400ms;
}

.flip {
  animation: flip 0.5s;
}

.emptyArrow {
  display: none;
}

.moveArrows {
  width: 32px;
}

.qcButton {
  border: 1px solid var(--general-border-color) !important;
  align-self: center;
  background-color: var(--bg-table-button) !important;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:first-child {
      display: none;
    }
    &:last-child {
      padding: 0.5em !important;
    }
  }
  &:hover {
    border-color: var(--button-secondary-border-color-hover) !important;
  }
}

.linkDisabled {
  pointer-events: none;
  cursor: default;
  svg {
    opacity: 0.3;
  }
}

.checkboxDisabled {
  cursor: default !important;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flip {
  0% {
    display: 'block';
  }
  40% {
    transform: rotateX(90deg);
  }
  100% {
    transform: rotateX(0deg);
    display: 'none';
  }
}

@keyframes fadeInBorder {
  0% {
    border: 0px dashed var(--c-gray-75);
    opacity: 0.2;
  }
  100% {
    border: 1px dashed var(--c-gray-75);
    opacity: 0.7;
  }
}

.PKWrap {
  .PKInfoButton {
    background-color: var(--bg-table-button) !important;
    border-radius: 50% !important;
    color: var(--datatable-header-info-button-color) !important;
    font-size: 12pt !important;
    height: 0.7em !important;
    width: 0.7em !important;
    margin-left: 0.4rem !important;
    margin-right: 0.9rem !important;
    margin-top: -1rem !important;
    :global .pi-info-circle {
      padding: 0 !important;
      top: 40%;
    }
    > span {
      left: 46% !important;
      margin-left: -0.48em !important;
      margin-top: -0.5em !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
}

@media (max-width: 1089px) {
  .dragAndDropIcon {
    display: none;
  }
  .draggableFieldContentCell {
    .draggableFieldCell {
      textarea {
        max-height: 33px !important;
      }
    }
  }
}

@media (min-width: 1090px) {
  .emptyCodelistOrLink {
    display: block;
    width: 4rem;
    margin-right: 0.4rem;
  }

  .dragAndDropItemsCell {
    width: 10%;
  }

  .dragAndDropItems {
    justify-content: space-between !important;
  }

  .codelistButton {
    width: 4rem;
    span {
      width: 100%;
    }
  }
  .smallItems {
    width: 5%;
  }

  .bigItems {
    width: 15%;
  }

  .draggableFieldDiv {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;

    .draggableFieldContentCell {
      height: auto;
      .draggableFieldCell {
        margin-right: 0.5em;
        width: 100%;
        border: none;
        padding: 3px 0;
        &:first-child {
          display: none;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        &:last-child {
          padding: 3px 0;
          div > label > svg {
            display: revert;
            float: right;
          }
        }
      }
    }
  }
}

@media (min-width: 1240px) {
  .qcButton {
    span {
      &:first-child {
        display: block;
      }
      &:last-child {
        padding: 0.429em 1em 0.429em 2.25em !important;
      }
    }
  }
}

@media (min-width: 1750px) {
  .dragAndDropItems {
    padding: 0 0 0 30% !important;
  }
}

@media (min-width: 1980px) {
  .smallItems {
    width: 5%;
  }
  .bigItems {
    width: 18%;
  }
}
