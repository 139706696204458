.title {
  color: #9f6000;
  background-color: #feefb3;
  border: 1px solid #ddd09d;
  border-radius: 5px;
  padding: 3px;
}

.switch {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.overviewButton {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  width: calc(150px + 2rem);
  height: 55px;
  margin: 1rem 0 1rem 0;
  opacity: 1;
  background-color: var(--tabview-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 0.3rem 2.2rem !important;
  button {
    color: var(--tabview-highlight-color);
    background-color: var(--bg);
    padding: 0.3rem 2.1rem;
    border: 0 !important;
    border-radius: 6px;
    font-size: 1rem !important;
    box-shadow: 0px 2px 5px rgb(11 37 105 / 4%), 0px 1px 0px rgb(11 37 105 / 4%);
    &:hover {
      background-color: var(--bg) !important;
      color: var(--tabview-highlight-color) !important;
    }
  }
}

.tableList {
  display: flex;
  flex-direction: column-reverse;
  padding: 0;

  .tableListItem {
    align-items: center;
    background-color: var(--tabview-bg);
    border-radius: 6px;
    display: flex;
    flex-flow: wrap;
    list-style: none;
    margin: 1rem 0;
    color: var(--main-font-color);
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .tableListTitleWrapper {
      width: 8%;
      .tableListTitle {
        border-radius: 6px;
        padding: 0.5rem;
      }
    }

    .tableListContentWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 77%;
      .tableListId {
        align-items: center;
        background-color: var(--button-disabled-webforms-bg-color);
        border-radius: 50%;
        box-shadow: var(--card-item-box-shadow);
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: 0.5rem 1rem;
        min-height: 40px;
        min-width: 40px;
        padding: 0.5rem;
        &:hover {
          box-shadow: var(--card-item-box-shadow-hover);
          background-color: var(--button-webform-hover-color);
          transform: scale(1.02, 1.02);
          transition: transform 0.1s ease;
        }
      }
      .selected {
        background-color: var(--button-primary-bg);
        color: var(--button-primary-color);
        &:hover {
          background-color: var(--button-primary-bg);
        }
      }
    }

    .addButtonWrapper {
      width: 15%;
      display: flex;
      justify-content: flex-end;
    }

    @media (max-width: 990px) {
      .tableListTitleWrapper {
        width: 15%;
      }
      .tableListContentWrapper {
        width: 60%;
      }
      .addButtonWrapper {
        width: 25%;
      }
    }
    @media (max-width: 590px) {
      flex-direction: column;
      align-items: center;
      .tableListTitleWrapper {
        width: 100%;
        text-align: center;
      }
      .tableListContentWrapper {
        width: 100%;
        margin: 1.5rem 0;
      }
      .addButtonWrapper {
        width: 100%;
        justify-content: center;
      }
    }
  }
}

.missingElements {
  color: var(--main-font-color);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 2rem 0;
  width: 100%;

  div {
    margin: 0 1rem;
  }

  .tableTitle {
    background-color: var(--tabview-bg);
    border-radius: 6px;
    display: flex;
    padding: 0.5rem;
    width: 200px;
  }

  ul {
    padding-inline-start: 30px;

    li {
      list-style: circle;
      padding: 0.2rem 0;
    }
  }
}
