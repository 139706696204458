.dialog_mask_wrapper {
  display: flex;
  z-index: 5000;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  ul li {
    margin-bottom: 0.5rem;
  }
  &.alert {
    :global .p-dialog-title {
      color: var(--errors);
    }
    :global .p-dialog-content {
      color: var(--errors);
    }
  }
}

.tabMenu {
  margin-bottom: 10px;
}
