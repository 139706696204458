.infoButtonWrapper {
  border-radius: 3px;
  border: var(--inputtext-border);
  padding: 1rem;
  display: flex;
  align-items: center;
  .infoButton {
    background-color: var(--c-white-color) !important;
    border-radius: 50% !important;
    color: var(--errors) !important;
    font-size: 12pt !important;
    height: 0.7em !important;
    margin-left: 0.4rem !important;
    width: 0.7em !important;
    margin-right: 0.25rem;

    > span {
      left: 46% !important;
      margin-left: -0.48em !important;
      margin-top: -0.5em !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }
}

.nonExistField {
  color: var(--errors);
}
.attachmentWrapper {
  button {
    margin-right: 0.5rem;
  }
}
