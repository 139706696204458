:root {
  --c-blue-140: #003d83;
  --c-blue-120: #004494;
  --c-blue-120-rgb: 0, 68, 148;
  --c-blue-110: #003776;
  --c-blue-90: #004494;
  --c-blue-75: #4073af;
  --c-blue-50: #7fa1c9;
  --c-blue-25: #bfd0e4;
  --c-blue-5: #f2f5f9;
  --c-yellow-120: #ffd617;
  --c-yellow-110: #fbc11d;
  --c-yellow-100: #ffd617;
  --c-yellow-75: #ffde39;
  --c-yellow-50: #ffe879;
  --c-yellow-25: #fff4bb;
  --c-black-color: #000000;
  --c-gray-110: #404040;
  --c-gray-75-opacity-08: rgba(112, 112, 112, 0.8);
  --c-gray-75: #707070;
  --c-gray-65: #898989;
  --c-gray-50: #9f9f9f;
  --c-gray-25: #cfcfcf;
  --c-gray-15: #e3e3e3;
  --c-gray-10: #ebebeb;
  --c-gray-5: #f5f5f5;
  --c-white-color: #ffffff;
  --c-white-color-opacity-08: rgba(255, 255, 255, 0.8);
  --c-white-font: #ffffffe7;
  --informative-color: #006fb4;
  --warning-color: #f29527;
  --success-color-lighter: #bcdbbc;
  --success-color: #467a39;
  --success-color-rgb: 70, 122, 57;
  --success-color-dark: #39632f;
  --success-color-darker: #305327;
  --success-color-light: #aae5aa;
  --errors: #da2131;
  --errors-dark-mode: #fc5050;
  --errors-light-mode: #da2131;
  --blockers: #fd2b3c;
  --shadow-color-rgba: rgba(0, 0, 0, 0.4);
  --accent-color-dark-mode: #e2c08d;

  /* Main corporate colors */
  --c-corporate-blue: #006699;
  --c-corporate-blue-rgb: 0, 102, 153;
  --c-corporate-blue-lighter: #0286c9;
  --c-corporate-aqua-marine: #008675;
  --c-corporate-yellow: #ffd902;
  --c-corporate-yellow-lighter: #ffec81;
  /* Blue palette */
  --c-blue-400: #4f758b;
  --c-blue-300: #6ba4b8;
  --c-blue-200: #6bcaba;
  --c-blue-100: #b1e4e3;
  /* khaki */
  --c-khaki-400: #787121;
  --c-khaki-300: #8e8c13;
  --c-khaki-200: #d2d755;
  --c-khaki-100: #bfcc80;
  /* orange */
  --c-orange-400: #e57200;
  --c-orange-300: #f0b323;
  --c-orange-200: #ffd100;
  --c-orange-100: #f0e991;
  /* red */
  --c-red-400: #861f41;
  --c-red-300: #a6093d;
  --c-red-200: #e40046;
  --c-red-100: #ee2737;
  --c-red-100-rgb: 238, 39, 55;
  --c-red-50: #ff0000;
  /* purple */
  --c-purple-400: #5c068c;
  --c-purple-300: #8246af;
  --c-purple-200: #ac4fc6;
  --c-purple-100: #dd7fd3;
  /* lilac */
  --c-lilac-500: #6f5091;
  --c-lilac-400: #5d5db1;
  --c-lilac-300: #6d6dc0;
  --c-lilac-200: #b4b5df;
  --c-lilac-100: #dbe2e9;
  /* black-rose */
  --c-black-rose-500: #252525;
  --c-black-rose-400: #31261d;
  --c-black-rose-300: #63513d;
  --c-black-rose-200: #c09c83;
  --c-black-rose-100: #d4b59e;
  /* black */
  --c-black-400: #000;
  --c-black-300: #75787b;
  --c-black-200: #b1b3b3;
  --c-black-100: #d0d0ce;
  /* pink */
  --c-pink-light: #f2d4d7;
  --c-pink: #e97780;
  --c-dark-pink: #d66670;
  /* individuals */
  --c-darkest-blue: #15171b;
  --c-darker-blue: #1a1c22;
  --c-dark-blue: #282c35;
  --c-purple: #733d85;
  --c-aquamarine: #007a86;
  --c-green: #637e22;
  --c-tile: #ba3c0d;
  /* custom colors */
  --c-blue-green: #009490;
  --c-chart-correct: #99cc33;
  --c-chart-warning: #ffcc00;
  --c-chart-error: #cc3300;
  --c-custom-gray: #c8c8c8;
  --c-growl-info-blue: #cae5fc;
  --c-growl-info-darker-blue: #5b94d4;
  --c-growl-success-darker-green: #5d854a;
  --c-growl-success-lighter-green: #d5e7cf;
  --c-growl-success-darker-green-hover: #486839;
  --c-growl-success-lighter-green-hover: #bacab4;
  --c-growl-warning-darker-yellow: #ceaf33;
  --c-growl-error-darker-red: #ca4b33;
  /* level error statusbox colors */
  --statusBox-blocker-bg: 194, 200, 207;
  --statusBox-blocker-color: 0, 0, 0;
  --statusBox-blocker-bg-hover: #a2aaa5;
  --statusBox-blocker-color-hover: #000000;
  --statusBox-error-bg: #ffcdd2;
  --statusBox-error-color: #a6093d;
  --statusBox-error-bg-hover: #ebbec2;
  --statusBox-error-color-hover: #920937;
  --statusBox-info-bg: #b3e5fc;
  --statusBox-info-color: #23547b;
  --statusBox-info-bg-hover: #a2d0e6;
  --statusBox-info-color-hover: #1f4869;
  --statusBox-warning-bg: #ffec81;
  --statusBox-warning-color: #805b36;
  --statusBox-warning-bg-hover: #e0d071;
  --statusBox-warning-color-hover: #6e4f2f;
  /* boolean statusbox colors*/
  --statusBox-true-bg: #c8e6c9;
  --statusBox-true-color: #256029;
  --statusBox-false-bg: #ffcdd2;
  --statusBox-false-color: #c63737;
  --statusBox-false-bg-hover: #e5babe;
  --statusBox-false-color-hover: #b33434;
  /* status  statusbox colors*/
  --statusBox-draft-bg: #eccfff;
  --statusBox-draft-color: #694382;
  --statusBox-designer-bg: #c2c8cf;
  /* Public page header background */
  --public-custom-header-bg: #033166;
  --public-page-header-bg: #fff;

  /* Dark mode backgrounds dp */
  --bg-dark-00dp: #11191d;
  --bg-dark-01dp: #1d2529;
  --bg-dark-02dp: #22292d;
  --bg-dark-03dp: #242b2f;
  --bg-dark-04dp: #262e31;
  --bg-dark-06dp: #2b3236;
  --bg-dark-08dp: #2e3538;
  --bg-dark-12dp: #33393d;
  --bg-dark-16dp: #343b3f;
  --bg-dark-24dp: #373e41;

  --header-height: 180px;

  /* Theme switcher */
  --bg: var(--c-white-color);
  --main-font-color: var(--c-gray-110);

  /* Chevron */
  --breadCrumb-font-color: var(--c-corporate-blue);
  --breadCrumb-icon-color: var(--c-corporate-blue);
  --breadCrumb-chevron-color: var(--c-corporate-blue-lighter);

  /* Leftside bar */

  --leftSideBar-bg: var(--c-corporate-blue);
  --leftSideBar-box-shadow: 0 5px 0.5rem rgba(0, 0, 0, 0.6);

  /* Header */
  --header-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);

  /* Title */
  --title-icon-color: var(--c-gray-110);
  --title-color: var(--c-gray-110);
  --title-icon-opacity: 0.6;
  --subtitle-color: var(--c-corporate-blue);

  /* Tabview */
  --tabview-bg: var(--c-gray-5);
  --tabview-border: 1px solid var(--c-gray-25);
  --tabview-color: var(--c-gray-110);
  --tabview-highlight-bg: var(--bg);
  --tabview-highlight-border: 1px solid var(--c-gray-25);
  --tabview-highlight-border-bottom: 0px;
  --tabview-highlight-color: var(--c-corporate-blue);
  --tabview-highlight-icon-color: var(--errors);
  --tabview-highlight-bg-hover: var(--c-corporate-yellow);
  --tabview-highlight-border-hover: 1px solid var(--c-gray-25);
  --tabview-highlight-color-hover: var(--c-gray-110);

  /* Tab menu */
  --tabmenu-highlight-bg: var(--bg);
  --tabmenu-highlight-border: 1px solid var(--c-gray-25);
  --tabmenu-highlight-border-bottom: 1px solid var(--bg);
  --tabmenu-highlight-color: var(--c-corporate-blue);

  /* Inputswitch */
  --inputswitch-checked-bg: var(--c-corporate-blue);
  --inputswitch-checked-before-bg: var(--c-white-color);
  --inputswitch-dark-theme-checked: inset 4px -1px var(--c-white-color);
  --inputswitch-unchecked-bg: var(--c-gray-25);
  --inputswitch-slider-bg: var(--c-white-color);

  /* Datatable */
  --datatable-header-bg: var(--c-gray-5);
  --datatable-header-color: var(--c-gray-110);
  --datatable-header-border: 1px solid var(--c-custom-gray);
  --datatable-body-border: 1px solid var(--c-custom-gray);
  --datatable-body-color: var(--c-black-color);
  --datatable-body-bg: var(--c-white-color);
  --datatable-body-even-bg: var(--c-gray-5);
  --datatable-body-highlight-bg: var(--c-gray-10);

  --datatable-column-sortable-hightlight-bg: var(--c-corporate-blue);
  --datatable-column-sortable-hightlight-color: var(--c-white-color);
  --datatable-column-sortable-hightlight-icon-color: var(--c-white-color);
  --datatable-column-sortable-hightlight-box-shadow-focus: inset 0 0 0 0.1em var(--c-gray-25);
  --datatable-header-footer-bg: var(--c-white-color);
  --datatable-header-footer-color: var(--c-gray-110);
  --datatable-header-footer-border: 1px solid var(--c-gray-25);
  --datatable-header-info-button-color: var(--c-corporate-blue);
  --datatable-column-sortable-icon-color: var(--c-gray-110);
  --datatable-column-sortable-hightlight-bg-hover: var(--c-corporate-yellow);
  --datatable-column-sortable-hightlight-color-hover: var(--c-gray-110);
  --datatable-column-sortable-icon-hightlight-color-hover: var(--c-gray-110);
  --datatable-body-row-toggler-infotable-bg: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 10px,
    rgba(218, 33, 49, 0.3) 10px,
    rgba(218, 33, 49, 0.3) 15px
  ) !important;
  --datatable-body-row-toggler-infotable-border: 1px solid var(--c-gray-10);
  --datatable-body-row-toggler-color: var(--c-gray-10);
  --datatable-body-row-toggler-color-hover: var(--c-gray-110);
  --datatable-body-row-border: 1px solid rgba(0, 0, 0, 0);
  --datatable-body-row-highlight-bg: rgba(240, 233, 145, 0.3);
  --datatable-body-row-highlight-color: var(c-gray-110);
  --datatable-body-row-toggler-hightlight-color: var(--c-corporate-blue);
  --datatable-body-row-toggler-hightlight-color-hover: var(--c-white-color);
  --datatable-body-row-contextmenu-hightlight-bg: var(--c-corporate-blue);
  --datatable-body-row-contextmenu-hightlight-color: var(--c-white-color);
  --datatable-column-resizer-bg: var(--c-corporate-blue);
  --datatable-toolbar-border: 1px solid var(--c-gray-25);

  /* Dataflow item */
  --dataflow-item-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.4);
  --dataflow-item-box-shadow-hover: 0 10px 20px rgba(0, 0, 0, 0.21), 0 6px 6px rgba(0, 0, 0, 0.25);
  --dataflow-item-transition: all 0.1s ease-in;

  /* Paginator */
  --paginator-bg: var(--c-corporate-blue);
  --paginator-border: 1px solid var(--c-blue-75);
  --paginator-color: var(--c-white-color);
  --paginator-bg-hover: var(--c-gray-10);
  --paginator-color-hover: var(--c-gray-110);
  --paginator-dataflows-pages-not-highlight-hover-border: 1px solid var(--c-lilac-400);
  --paginator-dropdown-label-color: var(--c-gray-110);
  --paginator-dropdown-label-color-hover: var(--c-gray-110);
  --paginator-pages-not-highlight-hover-bg: var(--c-white-color);
  --paginator-pages-not-highlight-hover-color: var(--c-gray-110);
  --paginator-pages-not-highlight-hover-border: 1px solid var(--c-corporate-blue);
  --paginator-pages-highlight-box-shadow: inset 0 0 0 0.1em var(--c-gray-75);
  --paginator-pages-highlight-bg: var(--c-white-color);
  --paginator-pages-highlight-color: var(--c-black-color);
  --paginator-pages-color: var(--c-white-color);
  --paginator-right-content: var(--c-white-color);

  /* Dropdown */
  --dropdown-bg: var(--bg);
  --dropdown-border: 1px solid var(--c-gray-50);
  --dropdown-border-color-hover: var(--c-gray-75);
  --dropdown-bg-hover: var(--c-white-color);
  --dropdown-bg-focus: var(--c-corporate-blue);
  --dropdown-trigger-bg: var(--c-white-color);
  --dropdown-trigger-color: var(--c-gray-50);
  --dropdown-trigger-clear-icon-color: var(--c-gray-50);
  --dropdown-panel-border: 1px solid var(--c-gray-25);
  --dropdown-panel-bg: var(--c-white-color);
  --dropdown-panel-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --dropdown-panel-filter-border: 1px solid var(--c-gray-10);
  --dropdown-panel-filter-color: var(--c-gray-110);
  --dropdown-panel-filter-bg: var(--c-white-color);
  --dropdown-panel-filter-icon-color: var(--c-corporate-blue);
  --dropdown-panel-items-group-color: var(--c-gray-110);
  --dropdown-panel-items-group-highlight-color: var(--c-white-color);
  --dropdown-panel-items-group-highlight-bg: var(--c-corporate-blue);
  --dropdown-panel-items-group-not-highlight-color-hover: var(--c-gray-110);
  --dropdown-panel-items-group-not-highlight-bg-hover: var(--c-gray-25);

  /* DropdownFilter */
  --dropdownFilter-border: 1px solid var(--c-black-200);
  --dropdownFilter-bg-color: var(--c-white-color);
  --dropdownFilter-color: var(--c-black-400);
  --dropdownFilter-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  --dropdownFilter-border-color-hover: var(--c-gray-25);
  --dropdownFilter-icon-color: var(--c-corporate-blue);

  /* Dropdown menu */
  --dropdown-menu-bg-color: var(--bg);
  --dropdown-menu-items-bg-color-hover: var(--c-gray-15);

  /* Floating label */
  --floating-label-color: var(--c-gray-65);

  /* Growl */
  --growl-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  --growl-info-bg: var(--c-growl-info-blue);
  --growl-info-color: var(--c-black-color);
  --growl-info-icon-color: var(--c-growl-info-darker-blue);
  --growl-info-border-color: var(--c-growl-info-darker-blue);
  --growl-success-bg: var(--c-growl-success-lighter-green);
  --growl-success-color: var(--c-black-color);
  --growl-success-icon-color: var(--c-growl-success-darker-green);
  --growl-success-border-color: var(--c-growl-success-darker-green);
  --growl-warning-bg: var(--c-corporate-yellow-lighter);
  --growl-warning-color: var(--c-black-color);
  --growl-warning-icon-color: var(--c-growl-warning-darker-yellow);
  --growl-warning-border-color: var(--c-growl-warning-darker-yellow);
  --growl-error-bg: var(--c-pink-light);
  --growl-error-color: var(--c-black-color);
  --growl-error-icon-color: var(--c-growl-error-darker-red);
  --growl-error-border-color: var(--c-growl-error-darker-red);
  --growl-close-icon-color: var(--c-gray-75);
  --growl-details-message-color: var(--c-gray-110);
  --growl-system-notification-bg: var(--c-gray-5);

  /* Inputtext */
  --inputtext-color: var(--main-font-color);
  --inputtext-bg: var(--bg);
  --inputtext-border: 1px solid var(--c-gray-50);
  --inputtext-border-color-hover: var(--c-gray-110);
  --inputtext-border-color-focus: var(--c-corporate-blue);
  --inputtext-box-shadow-color-focus: var(--c-corporate-blue-lighter);
  --inputtext-box-shadow-focus: 0 0 0 0.1em var(--c-corporate-blue-lighter);
  --inputtext-placeholder: var(--main-font-color);
  --inputtext-border-error: 1px solid var(--errors);
  --inputtext-box-shadow-focus-error: 0 0 0 0.1em var(--errors);

  /* Treeview */
  --treeview-expandable-color: var(--c-corporate-blue);
  --treeview-property-title-color: var(--c-black-color);
  --treeview-property-value-color: var(--c-black-color);
  --treeview-empty-property-color: var(--c-black-color);
  --treeview-table-icon-color: var(--c-black-color);

  /* Documentation */
  --documenticon-color: var(--c-corporate-blue);

  /* Dialog */
  --dialog-header-bg: var(--c-gray-10);
  --dialog-header-icon-color: var(--c-gray-75);
  --dialog-header-icon-color-hover: var(--c-gray-110);
  --dialog-content-bg: var(--c-white-color);
  --dialog-footer-bg: var(--c-white-color);
  --dialog-header-border: 1px solid var(--c-gray-25);
  --dialog-content-border: 1px solid var(--c-gray-25);
  --dialog-footer-border: 1px solid var(--c-gray-25);
  --dialog-header-color: var(--c-black-color);
  --dialog-content-color: var(--c-black-color);
  --dialog-footer-color: var(--c-black-color);

  /* Buttons */
  --button-primary-bg: var(--c-corporate-blue);
  --button-primary-bg-hover: var(--c-blue-120);
  --button-primary-bg-active: var(--c-blue-140);
  --button-primary-border: 1px solid var(--c-corporate-blue);
  --button-primary-border-color-hover: var(--c-blue-120);
  --button-primary-border-color-active: var(--c-blue-140);
  --button-primary-color: var(--c-white-color);
  --button-primary-color-hover: var(--c-white-color);
  --button-primary-color-icon: var(--c-white-color);
  --button-primary-color-active: var(--c-white-color);
  --button-primary-box-shadow-focus: 0 0 0 0.2em var(--c-blue-300);

  --button-success-bg: var(--success-color);
  --button-success-bg-hover: var(--success-color-dark);
  --button-success-bg-active: var(--success-color-darker);
  --button-success-border: 1px solid var(--success-color);
  --button-success-border-color-hover: var(--success-color-dark);
  --button-success-border-color-active: var(--success-color-darker);
  --button-success-color: var(--c-white-color);
  --button-success-color-hover: var(--c-white-color);
  --button-success-color-icon: var(--c-white-color);
  --button-success-color-active: var(--c-white-color);
  --button-success-box-shadow-focus: 0 0 0 0.2em var(--success-color-light);

  --button-secondary-bg: var(--c-gray-10);
  --button-secondary-bg-hover: var(--c-gray-25);
  --button-secondary-bg-active: var(--c-gray-50);
  --button-secondary-border: 1px solid var(--c-gray-10);
  --button-secondary-border-color-hover: var(--c-gray-25);
  --button-secondary-border-color-active: var(--c-gray-25);
  --button-secondary-color: var(--c-gray-110);
  --button-secondary-color-hover: var(--c-gray-110);
  --button-secondary-color-icon: var(--c-gray-110);
  --button-secondary-color-active: var(--c-gray-110);
  --button-secondary-box-shadow-focus: 0 0 0 0.2em var(--c-gray-25);

  --button-secondary-transparent-bg: transparent;
  --button-secondary-transparent-bg-hover: var(--c-gray-25);
  --button-secondary-transparent-bg-active: var(--c-gray-50);
  --button-secondary-transparent-border: none;
  --button-secondary-transparent-border-color-hover: var(--c-gray-25);
  --button-secondary-transparent-border-color-active: var(--c-gray-25);
  --button-secondary-transparent-color: var(--c-gray-110);
  --button-secondary-transparent-color-hover: var(--c-gray-110);
  --button-secondary-transparent-color-icon: var(--c-gray-110);
  --button-secondary-transparent-color-active: var(--c-gray-110);
  --button-secondary-transparent-box-shadow-focus: 0 0 0 0.2em var(--c-gray-25);

  --button-secondary-icon-only-bg: var(--c-gray-10);
  --button-secondary-icon-only-bg-hover: var(--c-gray-25);
  --button-secondary-icon-only-bg-active: var(--c-gray-50);
  --button-secondary-icon-only-border: 1px solid var(--c-gray-10);
  --button-secondary-icon-only-border-color-hover: var(--c-gray-25);
  --button-secondary-icon-only-border-color-active: var(--c-gray-25);
  --button-secondary-icon-only-color: var(--c-gray-110);
  --button-secondary-icon-only-color-hover: var(--c-gray-110);
  --button-secondary-icon-only-color-icon: var(--c-gray-110);
  --button-secondary-icon-only-color-active: var(--c-gray-110);
  --button-secondary-icon-only-box-shadow-focus: 0 0 0 0.2em var(--c-gray-25);

  /* Chipbutton */
  --chipbutton-secondary-transparent-bg: var(--c-gray-10);
  --chipbutton-secondary-transparent-bg-hover: var(--c-gray-25);

  /* RadioButton */
  --radioButton-dc-hover-bg: var(--c-gray-5);

  /* Toolbar */
  --toolbar-border: 1px solid var(--c-gray-10);

  /* Chart */
  --chart-bg: var(--bg);
  --chart-color: var(--main-font-color);

  /* Multiselect */
  --multiselect-bg: var(--c-white-color);
  --multiselect-border: 1px solid var(--c-gray-50);
  --multiselect-bg-hover: var(--c-gray-110);
  --multiselect-label-color: var(--c-gray-110);
  --multiselect-trigger-bg: var(--c-white-color);
  --multiselect-trigger-color: var(--c-gray-50);
  --multiselect-panel-border: 1px solid var(--c-gray-25);
  --multiselect-panel-bg: var(--c-white-color);
  --multiselect-panel-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --multiselect-panel-header-border-bottom: 1px solid var(--c-gray-10);
  --multiselect-panel-header-color: var(--c-gray-110);
  --multiselect-panel-header-bg: var(--c-white-color);
  --multiselect-panel-header-filter-icon-color: var(--c-corporate-blue);
  --multiselect-panel-header-close-color: var(--c-gray-25);
  --multiselect-panel-item-color: var(--c-gray-110);
  --multiselect-panel-item-color-hover: var(--c-gray-110);
  --multiselect-panel-item-bg-hover: var(--c-gray-5);

  /* Checkbox */
  --checkbox-box-border: 1px solid var(--c-gray-50);
  --checkbox-box-bg: var(--c-white-color);
  --checkbox-box-bg-hover: var(--c-black-color);
  --checkbox-box-box-shadow-focus: 0 0 0 0.2em var(--c-corporate-blue);
  --checkbox-box-bg-focus: var(--c-corporate-blue);
  --checkbox-box-highlight-border-color: var(--c-corporate-blue);
  --checkbox-box-highlight-bg: var(--c-corporate-blue);
  --checkbox-box-highlight-color: var(--c-white-color);
  --checkbox-box-highlight-border-color-hover: var(--c-corporate-blue-lighter);
  --checkbox-box-highlight-bg-hover: var(--c-corporate-blue-lighter);
  --checkbox-box-highlight-color-hover: var(--c-white-color);

  /* InputTextArea */
  --inputtextarea-box-shadow: 0 10px 6px -6px rgba(var(--c-corporate-blue-rgb), 0.1);

  /* FieldDesigner */
  --field-designer-separator-box-shadow: 1px 1px rgba(var(--c-corporate-blue-rgb), 0.2),
    2px 2px rgba(var(--c-corporate-blue-rgb), 0.2), 3px 3px rgba(var(--c-corporate-blue-rgb), 0.2);
  --field-designer-separator-bg: rgba(var(--c-corporate-blue-rgb), 0.2);
  --field-designer-separator-border-color: var(--c-gray-75);

  /* SideBar */
  --sidebar-bg: var(--c-white-color);
  --sidebar-color: var(--c-gray-110);
  --sidebar-border: 1px solid var(--c-gray-50);
  --sidebar-boxshadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  --sidebar-scrollbar-color: var(--c-gray-75) var(--c-custom-gray);
  --sidebar-scrollbar-bg: var(--c-custom-gray);
  --sidebar-scrollbar-thumb-bg: var(--c-gray-50);
  --sidebar-scrollbar-thumb-bg-hover: var(--c-gray-75);
  --sidebar-close-icon-color: var(--c-gray-50);
  --sidebar-close-icon-color-hover: var(--c-gray-110);
  --sidebar-title-color: var(--c-corporate-blue);

  /* Form field */
  --form-field-bg: var(--c-white-color);
  --form-field-color: var(--c-black-color);

  /* Hyperlinks */
  --hyperlink-color: var(--c-corporate-blue);
  --hyperlink-color-hover: var(--c-corporate-blue-lighter);
  --hyperlink-color-active: var(--c-corporate-blue-lighter);
  --hyperlink-color-visited: var(--c-corporate-blue);

  /* Drag&drop */
  --drag-and-drop-arrow-color: var(--c-corporate-blue);
  --drag-and-drop-arrow-opacity: 0.6;
  --drag-and-drop-div-border: 1px dashed var(--c-corporate-blue);
  --drag-and-drop-div-border: 5px dashed var(--c-corporate-yellow);
  --drag-and-drop-div-opacity: 0.7;
  --drag-and-drop-div-low-opacity: 0.3;

  /* Chips */
  --chips-input-box-shadow: 0 0 0 0.2em var(--c-corporate-blue);
  --chips-input-box-shadow-error: 0 0 0 0.2em var(--errors);
  --chips-input-border-color: var(--c-corporate-blue);
  --chips-input-border-color-hover: var(--c-corporate-blue);
  --chips-input-color: var(--c-black-color);
  --chips-token-bg: var(--c-corporate-blue);
  --chips-token-color: var(--c-white-color);

  /* ListBox */
  --listbox-item-highlight-bg: var(--c-corporate-blue);
  --listbox-item-highlight-bg-hover: var(--c-gray-10);
  --listbox-item-highlight-color: var(--c-white-color);
  --listbox-item-pk-indicator-bg: var(--c-gray-25);
  --listbox-item-pk-indicator-color: var(--main-font-color);

  /* Tooltip */
  --tooltip-body-bg: var(--c-gray-110);
  --tooltip-body-color: var(--c-white-color);
  --tooltip-body-border-color: var(--c-gray-110);
  --tooltip-arrow-border-color: var(--c-gray-110);
  --tooltip-arrow-bg: var(--c-gray-110);

  /* User image */
  --user-image-bg-hover: var(--main-font-color);
  --user-image-bg-opacity: 0.6;

  /* Calendar */
  --calendar-bg: var(--bg);
  --calendar-color: var(--c-gray-75);
  --calendar-border-color: var(--c-custom-gray);
  --calendar-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  --calendar-body-span-color: var(--c-gray-110);
  --calendar-header-next-prev-color: var(--c-gray-75);
  --calendar-header-bg: var(--bg);
  --calendar-header-color: var(--c-gray-75);

  /* External integrations */
  --external-integration-item-border: 0.05rem solid var(--c-gray-25);
  --external-integration-item-bg-color-selected: var(--c-gray-10);
  --external-integration-item-bg-color-hover: var(--c-gray-10);

  /* Map */
  --map-marker-color: var(--c-corporate-blue-lighter);
  --map-new-marker-color: var(--c-green);

  /* EU header & footer */
  --eu-header-shadow: 0 4px 5px 0 var(--shadow-color-rgba);
  --eu-header-bg: var(--c-gray-110);
  --eu-footer-bottom-bg: #d9d9d9;
  --eu-footer-bottom-font: #016394;
  --eu-footer-bottom: var(--eu-footer-bottom-bg);

  /* Custom header */
  --header-footer-bg: var(--c-gray-10);

  /* Footer */
  --footer-border: 8px solid var(--c-corporate-blue);

  /* Card */
  --card-item-bg: var(--bg);
  --card-item-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 2px rgba(0, 0, 0, 0.4);
  --card-item-box-shadow-hover: 0 10px 20px rgba(0, 0, 0, 0.21), 0 6px 6px rgba(0, 0, 0, 0.25);

  /* Cookies notice */
  --cookies-bg: #033166;

  /* Feedback chat */
  --feedback-separator-color: var(--c-corporate-blue);
  --feedback-drag-color: var(--c-corporate-yellow);

  /* Webforms */
  --webform-table-bg: var(--c-gray-10);
  --webform-table-border: 1px solid var(--c-gray-10);
  --general-text-color: var(--grey-110);

  /* Fileupload */
  --fileupload-bg-color: var(--bg);
  --fileupload-header-bg-color: var(--bg);

  /* Accordion */
  --accordion-content-bg-color: var(--bg);
  --accordion-content-border: 1px solid var(--c-gray-25);
  --accordion-header-border: 1px solid var(--c-gray-25);
  --accordion-header-bg-color: var(--c-gray-10);
  --accordion-header-bg-color-hover: var(--c-gray-25);
  --accordion-header-icon-color: var(--main-font-color);
  --accordion-header-border-hover: 1px solid var(--c-gray-25);
  --accordion-header-color: var(--main-font-color);
  --accordion-header-highlight-border-color: 1px solid var(--c-gray-25);
  --accordion-header-highlight-color: var(--main-font-color);
  --accordion-header-highlight-bg-color: var(--c-gray-10);
  --accordion-header-highlight-bg-color-hover: var(--c-gray-25);
  --accordion-header-highlight-border-hover: 1px solid var(--accordion-header-highlight-bg-color-hover);

  /* Borders */
  --general-border-style: 1px solid var(--general-border-color);

  /* Left sidebar */
  --left-sidebar-text-color: var(--c-white-color);

  /* Cookies */
  --cookies-content-color: var(--c-white-color);

  /* Go top button */
  --go-top-button-bg-color-hover: var(--c-corporate-blue);
  --go-top-button-bg-color: var(--c-corporate-blue);
  --go-top-button-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  --go-top-button-color-hover: var(--c-corporate-yellow);
  --go-top-button-color: var(--c-white-color);

  /* Traffic light*/
  --traffic-light-red-active: var(--c-red-50);
  --traffic-light-yellow-active: var(--c-orange-200);
  --traffic-light-green-active: var(--c-chart-correct);

  /* Dataflows list */
  --dataflows-list-paginator-bg-color: var(--c-lilac-300);

  /* Step progress bar */
  --step-progress-bar-activeCompleted-bg-color: var(--success-color);
  --step-progress-bar-activeIncompleted-bg-color: var(--c-corporate-blue);
  --step-progress-bar-icon-activeIncompleted-border: 2px solid var(--c-corporate-blue);
  --step-progress-bar-activeCompleted-color: var(--c-white-color);
  --step-progress-bar-icon-activeCompleted-border: 2px solid var(--success-color);
  --step-progress-bar-inactive-color: var(--c-gray-65);
  --step-progress-bar-inactive-bg-color: var(--bg);
  --step-progress-bar-icon-border: 2px solid var(--c-gray-65);
  --step-progress-bar-link-bg-color: var(--c-gray-65);
  --step-progress-bar-link-box-shadow: 0 2px 3px rgb(70 122 57 / 50%), 0 1px 2px rgb(70 122 57 / 50%),
    0 -3px 2px rgb(70 122 57 / 4%), 0 -2px 2px rgb(70 122 57 / 50%);
  --step-progress-bar-link-done-bg-color: var(--success-color);
  --step-progress-bar-label-color: var(--main-font-color);
  --step-progress-bar-withError-bg-color: var(--errors);
  --step-progress-bar-withError-color: var(--c-white-color);
}
