.wrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.titleWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    padding: 0;
    margin: 0;
  }
}
.euFlagWrapper {
  min-width: 32px;
}
.content {
  display: flex;
  flex: auto;
  margin-bottom: 3rem;

  .repContainer {
    display: flex;
    flex-direction: column;
    h1 {
      margin-top: 2.5rem;
      font-size: 2.25rem;
    }

    h2 {
      margin-top: -0.2rem !important;
    }

    .countriesWrapper {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 4rem;
      .euFlag {
        width: 32px;
        opacity: 0.6;
      }
      > div {
        box-sizing: border-box;
        width: 295px;
        margin-bottom: 1rem;
        margin-right: 1rem;
        transition: all 0.2s ease;
        border-radius: 15px;
        box-shadow: var(--card-item-box-shadow);
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        position: relative;
        color: var(--hyperlink-color);

        &:hover {
          box-shadow: var(--card-item-box-shadow-hover);
          transform: scale(1.02, 1.02);
          transition: transform 0.2s ease;
        }

        > a:hover {
          color: var(--c-corporate-blue) !important;
        }
        .flag {
          box-shadow: var(--card-item-box-shadow);
          outline: none;
          font-size: 5em;
          height: 0.75em !important;
          line-height: 1.5em;
          margin: 10px 0 10px 10px;
          opacity: 0.5;
          top: 0;
          width: 1em;
        }
      }
      > div:hover .flag {
        opacity: 1;
      }
      > div:hover .euFlag {
        opacity: 1;
      }
      > div:hover .country {
        opacity: 1;
      }
      .country {
        opacity: 0.5;
        float: right;
        position: absolute;
        right: 10px;
        top: 0;
      }
    }
  }
}
