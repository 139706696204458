.dropDownWrapper {
  position: relative;
}
.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;

  .dropdown_content {
    right: 0px;
  }
}

.icon {
  font-size: 1.5rem;
}
