.pointEpsgWrapper {
  align-items: center;
  display: flex;
  margin-top: 0.5rem;
  .pointInput {
    width: 45% !important;
  }
  .pointInputError {
    border: var(--inputtext-border-error);
    box-shadow: var(--inputtext-box-shadow-focus-error) !important;
  }
  button {
    width: 35%;
  }
}

.saveButton {
  margin-top: 0.5rem !important;
}
