.noDataflows {
  color: var(--button-secondary-transparent-color-hover);
  font-weight: 450;
  margin: 2rem 1rem;
}

.checkColumn {
  text-align: center;
}

.titleColum {
  display: flex;
  justify-content: space-between;

  .linkIcon {
    color: var(--main-font-color);
    cursor: pointer;
    float: center;
  }
}

.emptyTableHeader {
  :global .p-column-title {
    display: none;
  }
}
