.trafficLight {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 4.6rem;
  height: 1.6rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  border-radius: 10px;
  border: 1px solid var(--c-custom-gray) !important;
  background-color: var(--traffic-light-bg);
  > div {
    height: 0.9rem;
    width: 0.9rem;
    border-radius: 50%;
    background-color: var(--traffic-light-radius);
  }

  .redLightSignal {
    background: var(--traffic-light-red-active);
  }

  .yellowLightSignal {
    background: var(--traffic-light-yellow-active);
  }

  .greenLightSignal {
    background: var(--traffic-light-green-active);
  }
}
