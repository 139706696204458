.loginBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--c-blue-120);
}
.loginBoxContainer .loginBox {
  background-color: var(--c-white-color);
  padding: 1rem;
  border-radius: 5px;
  width: 400px;
}
.loginBoxContainer .logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
.loginBoxContainer .loginBox .logo h1 {
  font-size: var(--heading-1);
}
.loginBoxContainer .logo {
  width: 150px;
}

.loginBoxContainer .loginBox a {
  cursor: pointer;
}

.error {
  padding: 1rem;
  border: 2px solid var(--errors);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-bottom: 1rem;
  color: var(--errors);
  margin: 1rem;
}
