@import '_styles/mixins';

.descriptionLabel {
  margin: 0.5vw 0 0.5vw 1vw;
  color: var(--main-font-color);
}
.fieldDesignerItem {
  margin-right: 50px !important;
}
.fieldDesignerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-wrap: wrap-reverse;
  z-index: 3000;
}
.fieldDesignerWrapper:last-child {
  margin-bottom: 1rem;
}

.contentTable {
  width: 100%;
  .spinner {
    position: absolute;
    top: 75%;
  }
}

.fieldsHeader {
  display: none;
  color: var(--main-font-color);
  font-weight: bold;

  label {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      text-align: center;
      word-wrap: break-word;
    }
    /*  &:nth-child(2) {
      width: 75px;
    }
    &:nth-child(3) {
      width: 85px;
    }
    &:nth-child(4) {
      width: 20%;
      margin-right: 0.4rem;
      &.withCodelistOrLink {
        width: 15%;
      }
    }
    &:nth-child(5) {
      width: 15%;
      @include for-big-desktop-up {
        width: 25%;
      }
      margin-right: 0.4rem;
    }
    &:nth-child(6) {
      width: 20%;
      margin-right: 0.4rem;
    }
    &:nth-child(7) {
      &.withCodelistOrLink {
        width: 4rem;
        margin-right: 0.4rem;
      }
    }
    &:nth-child(8) {
      width: 9rem;
      margin-right: 0.4rem;
    }
    &:nth-child(9) {
      width: 2rem;
      margin-right: 0.7rem;
    }
    &:nth-child(10) {
      width: 2rem;
      margin-right: 0.4rem;
      display: flex;
      div {
        &:second-child {
          background-color: var(--statusBox-designer-bg);
        }
      }
    }
    .disabledButton {
      pointer-events: none !important;
      text-decoration: none;
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: transparent;
      }
    }
    .bulkDeleteButton {
      cursor: pointer;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      border-radius: 3px;
      border: 1px solid var(--c-custom-gray);
      &:hover {
        background-color: var(--c-custom-gray);
      }
    }
    .bulkConfirmDeleteButton {
      background-color: var(--errors);
      color: var(--c-white-color);
    }
    .bulkCancelDeleteButtonTooltip {
      font-size: 9pt;
      font-style: italic;
    }*/
  }
  .PKWrap {
    text-align: center;

    .PKInfoButton {
      background-color: var(--c-white-color) !important;
      border-radius: 50% !important;
      color: var(--datatable-header-info-button-color) !important;
      font-size: 12pt !important;
      height: 0.7em !important;
      width: 0.7em !important;
      margin-left: 0.4rem !important;
      margin-right: 0.9rem !important;
      margin-top: -1rem !important;
      :global .pi-info-circle {
        padding: 0 !important;
        top: 40%;
      }
      > span {
        left: 46% !important;
        margin-left: -0.48em !important;
        margin-top: -0.5em !important;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
.positioning {
  top: 0 !important;
}
.switchContainerInput {
  width: 100vw;
}
.switchDiv {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.switchDiv div:nth-child(-n + 3) {
  margin-bottom: 0.3rem;
}

.tableDescriptionRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1vw 1vw;
  .constraintsButtons {
    display: flex;
    flex-direction: column;
    button:last-child {
      margin-top: 5px;
    }
  }
}
.switchTextInput {
  font-weight: bold;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 5px;
  color: var(--main-font-color);
}
.tableDescriptionInput {
  width: 40vw;
  transition: 0.5s !important;
}
.markedForDeletionList {
  margin-top: 1rem;
  max-height: 40vh;
  overflow: scroll;
  li {
    margin-bottom: 1rem !important;
    display: flex;
    div {
      &:not(.tooltip) {
        width: 12rem;
      }
      margin-right: 2rem;
      span {
        background-color: var(--statusBox-designer-bg);
        border-radius: 3px;
        color: var(--c-black-color);
        font-size: 10pt;
        font-weight: 600;
        margin-right: 1rem;
        margin-top: 2px;
        padding: 4px;
      }
      svg {
        color: var(--c-corporate-blue);
        font-size: 10pt;
      }
    }
  }
}

.inactiveDragAndDropItems ~ div > div:first-child {
  svg {
    opacity: 0.5 !important;
  }
}

.activeDragAndDropItems ~ div > div:first-child {
  svg {
    cursor: pointer;
  }
}

:global body .p-inputswitch.p-inputswitch-focus .p-inputswitch-slider {
  @include shadow-0em;
}

@media (min-width: 1090px) {
  .fieldsHeader {
    display: grid;
    grid-template-columns: 10% 5% 5% 5% 15% 15% 15% 4% 8% 9% 9%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

@media (min-width: 1750px) {
  .PKWrap {
    label {
      margin-left: 1.2rem;
    }
  }
  .fieldsHeader {
    grid-template-columns: 10% 5% 5% 5% 15% 15% 15% 9% 7% 7% 7%;
  }
}

@media (min-width: 1980px) {
  .fieldsHeader {
    grid-template-columns: 10% 5% 5% 5% 18% 18% 18% 3% 6% 6% 6%;
  }
}
