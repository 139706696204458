@import '_styles/mixins';

.addUserTextButtonWrapper{
  margin-top:1rem;
}

.addUserTextLabel{
  margin-bottom:0.5rem;
  display:block;
}

.titleBar {
  display: flex;
  border-bottom: 1px solid var(--c-gray-25);
  margin: 20px 0;
  justify-content: space-between;
  .title_wrapper {
    .title {
      margin: 0;
      color: var(--main-font-color);
    }
  }
}

.calendarConfirm {
  min-width: 20rem !important;
  min-height: 20rem !important;

  .dataCollectionDialogMessagesWrapper {
    display: flex;
    flex-direction: column;
  }
}

.calendar {
  display: flex !important;
}

.dialog :global .p-dialog-content {
  padding-bottom: 0 !important;
}
.isAutomaticReportingDeletionLabel,
.isReleasableLabel,
.restrictFromPublic,
.showPublicInfo {
  padding-left: 0.5rem;
  a {
    color: var(--main-font-color);
    &:hover {
      color: var(--main-font-color);
      cursor: pointer;
    }
  }
}

.pointer {
  cursor: pointer;
}

.buttonLeft {
  float: left;
}

.exportImportMenu {
  user-select: none;
  width: auto !important;
}

.disabledLabel {
  color: var(--c-gray-65);
}

.restrictFromPublicNote {
  font-style: italic;
  margin-top: 1.5em;
}
