.headersWrapper {
  display: flex;
  align-items: center;
}

.scrollTab {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  text-overflow: unset;
  scroll-behavior: smooth;
}

.iconHidden {
  display: none !important;
}
.iconsWrapper {
  display: flex;
  position: relative;
}
.navigationTabIcons {
  color: var(--c-black-300);
  cursor: pointer !important;
  font-size: 1.75rem !important;
  margin-top: 1.75px;
}
.navigationTabIcons:hover {
  color: var(--c-black-color);
}
.scrollTab {
  scrollbar-color: var(--bg) var(--c-custom-gray);
  scrollbar-width: thin;
}
.scrollTab::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.scrollTab::-webkit-scrollbar-track {
  background: var(--bg);
  border-left: 1px solid var(--c-gray-25);
  border-right: 1px solid var(--c-gray-25);
}

.scrollTab::-webkit-scrollbar-thumb {
  background: var(--c-gray-75);
}

.scrollTab::-webkit-scrollbar-thumb:hover {
  background: var(--c-gray-50);
}
