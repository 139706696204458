.list {
  padding-left: 0px;
}
.section {
  margin: 2em 0;
}
.expressionsActionsBtns {
  button {
    margin-right: 0.5rem;
    margin-top: 1.2rem;
  }
}
