.wrap {
  margin-top:1rem;
  &:last-child {
    margin-bottom: 5rem;
  }

  > h2 {
    margin-bottom: 5px;
    color: var(--main-font-color);
  }

  > p {
    font-weight: 300;
    color: var(--main-font-color);
  }

  .noDataflows {
    margin: 8rem 0.5rem;
    text-align: center;
    color: var(--button-secondary-transparent-color-hover);
    font-size: 1.2rem;
    font-weight: bold;
  }

  .pinnedSeparator {
    border-top: 4px dashed var(--main-font-color);
    margin: 2rem 0;
    border-bottom: 0;
    opacity: 0.5;
  }
}

.spinnerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: bold;
  height: 40vh;
  width: 100%;
}

.spinner {
  left: 0 !important;
  top: 0 !important;
}

.compressedListWrapper{
  @media screen and (min-width:1300px){
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    gap:0 2rem;
  }
  hr[class*=_pinnedSeparator_]{
    grid-column-start: 1;
    grid-column-end: -1;
  }
}

.compressedButton.compressedButton{
  display:none;
  @media screen and (min-width:1300px){
    display:inline-block;
    margin-top:1rem;
  }
}
