body .p-tabview.p-tabview-top,
body .p-tabview.p-tabview-bottom,
body .p-tabview.p-tabview-left,
body .p-tabview.p-tabview-right {
  border: 0 none;
}
body .p-tabview.p-tabview-top .p-tabview-nav,
body .p-tabview.p-tabview-bottom .p-tabview-nav,
body .p-tabview.p-tabview-left .p-tabview-nav,
body .p-tabview.p-tabview-right .p-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li a {
  background-color: var(--tabview-bg);
  border: var(--tabview-border);
  float: none;
  display: flex;
  align-items: center;
  color: var(--tabview-color);
  padding: 0.571rem 1.3rem;
  font-weight: 700;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-left-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-left-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-left-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-left-icon {
  margin-right: 0.5em;
  color: var(--tabview-highlight-icon-color);
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-right-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-right-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-right-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li:not(.sameColor) a .p-tabview-right-icon {
  margin-left: 0.5em;
  color: var(--tabview-highlight-icon-color);
}
body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor.p-tabview-selected:not(.p-tav)
  a
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor.p-tabview-selected
  a
  .p-tabview-right-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.sameColor.p-tabview-selected a .p-tabview-right-icon,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor.p-tabview-selected
  a
  .p-tabview-right-icon {
  color: var(--tabview-highlight-icon-color-active);
}
body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor:not(.p-highlight)
  a:hover
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor:not(.p-highlight)
  a:hover
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor:not(.p-highlight)
  a:hover
  .p-tabview-right-icon,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li.p-tabview-nav-li.sameColor:not(.p-highlight)
  a:hover
  .p-tabview-right-icon {
  color: var(--tabview-highlight-icon-color-hover);
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li a:not(.p-disabled):focus,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li a:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li .p-tabview-close {
  color: #848484;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover a {
  background-color: var(--tabview-highlight-bg-hover);
  border: var(--tabview-highlight-border-hover);
  color: var(--tabview-highlight-color-hover);
}
body
  .p-tabview.p-tabview-top
  .p-tabview-nav
  li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close,
body
  .p-tabview.p-tabview-bottom
  .p-tabview-nav
  li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close,
body
  .p-tabview.p-tabview-left
  .p-tabview-nav
  li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close,
body
  .p-tabview.p-tabview-right
  .p-tabview-nav
  li.p-tabview-nav-li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-close {
  color: #404040;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li.p-highlight a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li.p-highlight a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.p-highlight a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li.p-highlight a {
  background-color: var(--tabview-highlight-bg);
  border-top: var(--tabview-highlight-border);
  border-right: var(--tabview-highlight-border);
  border-left: var(--tabview-highlight-border);
  border-bottom: var(--tabview-highlight-border-bottom);
  color: var(--tabview-highlight-color);
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.p-highlight .p-tabview-close,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li.p-highlight .p-tabview-close {
  color: #ffffff;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover {
  cursor: default;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a {
  cursor: default;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-right-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-right-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-right-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-left-icon,
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li.p-highlight:hover a .p-tabview-right-icon {
  color: var(--tabview-highlight-icon-color);
}

body .p-tabview.p-tabview-top .p-tabview-nav {
  margin-bottom: -1px;
}
body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li a {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}

body .p-tabview.p-tabview-top .p-tabview-nav li.p-tabview-nav-li:not(:last-child) a {
  margin-right: 2px;
}

body .p-tabview .p-tabview-nav li {
  list-style: none;
  float: left;
  position: relative;
  margin: 0 0 1px 0;
  padding: 0;
  white-space: nowrap;
  height: 2.6rem;
}

body .p-tabview.p-tabview-bottom .p-tabview-nav {
  margin-top: -1px;
}
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li a {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .p-tabview.p-tabview-bottom .p-tabview-nav li.p-tabview-nav-li a {
  margin-right: 2px;
}

body .p-tabview.p-tabview-left .p-tabview-nav {
  margin-right: -1px;
}
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li a {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .p-tabview.p-tabview-left .p-tabview-nav li.p-tabview-nav-li:not(:last-child) a {
  margin-bottom: 2px;
}

body .p-tabview.p-tabview-right .p-tabview-nav {
  margin-right: -1px;
}
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li a {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .p-tabview.p-tabview-right .p-tabview-nav li.p-tabview-nav-li:not(:last-child) a {
  margin-bottom: 2px;
}

body .p-tabview .p-tabview-panels {
  background-color: var(--bg);
  padding: 0.571em 1em;
  border: var(--tabview-border);
  color: var(--main-font-color);
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin-bottom: 30px;
}
