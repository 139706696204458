@import '_styles/mixins';
.tooltipButton {
  border-radius: 50%;
  width: 0.6em !important;
  height: 0.6em !important;
  font-size: 12pt !important;
  color: var(--datatable-header-info-button-color) !important;
  margin-left: 0.4rem !important;
  background-color: var(--c-white-color) !important;
  &:focus {
    box-shadow: var(--c-white-color) !important;
  }
  span:nth-of-type(1) {
    left: 46% !important;
    margin-top: -0.5em !important;
    margin-left: -0.48em !important;
  }
  span:nth-of-type(2) {
    font-size: x-small;
    padding: 0.25em !important;
  }
}

.tooltipButtonRed {
  border-radius: 50%;
  width: 0.6em !important;
  height: 0.6em !important;
  font-size: 12pt !important;
  color: var(--warning-color) !important;
  margin-left: 0.4rem !important;
  background-color: var(--c-white-color) !important;
  &:focus {
    box-shadow: var(--c-white-color) !important;
  }
  position: absolute !important;
  top:50% !important;
  transform: translateY(-50%);
  margin-top: 0 !important;
}

.maxWidth {
  max-width: 60vw;

  @media (min-width: 700px) {
    max-width: calc(60vw - 3%);
  }

  @media (min-width: 800px) {
    max-width: calc(60vw - 5%);
  }

  @media (min-width: 850px) {
    max-width: calc(60vw - 8%);
  }

  @include for-tablet-landscape-up {
    max-width: calc(60vw - 10%);
  }

  @media (min-width: 950px) {
    max-width: calc(60vw - 12%);
  }

  @include for-desktop-up {
    max-width: calc(60vw - 23%);
  }

  @include for-big-desktop-up {
    max-width: calc(60vw - 25%);
  }
}
